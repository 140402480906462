import React, { useState, useEffect } from 'react';

const SitaAIFlywheel = () => {
  const [activeHighlights, setActiveHighlights] = useState(Array(10).fill(false));

  const sections = [
    { inner: "AI Career Guidance", outer: "Get AI-powered career counseling" },
    { inner: "Skill Development", outer: "Engage in targeted skill development" },
    { inner: "Interview Preparation", outer: "Practice and prepare for interviews" },
    { inner: "Job Matching", outer: "Connect with potential employers" },
    { inner: "Community Contribution", outer: "Successful users become mentors" }
  ];

  const opacity = 0.4;
  const neutralColor = `rgba(200, 200, 200, ${opacity})`;
  
  // 10. Energetic Burst
  const brightPastelColors10 = [
    [`rgba(255, 69, 0, ${opacity})`, `rgba(255, 0, 0, ${opacity})`],        // Red-Orange
    [`rgba(255, 215, 0, ${opacity})`, `rgba(255, 165, 0, ${opacity})`],     // Gold
    [`rgba(0, 255, 127, ${opacity})`, `rgba(60, 179, 113, ${opacity})`],    // Spring Green
    [`rgba(30, 144, 255, ${opacity})`, `rgba(0, 0, 255, ${opacity})`],      // Dodger Blue
    [`rgba(218, 112, 214, ${opacity})`, `rgba(186, 85, 211, ${opacity})`]   // Orchid
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveHighlights((prev) => {
        const allActive = prev.every(Boolean);
        if (allActive) {
          return Array(10).fill(false);
        } else {
          const nextIndex = prev.findIndex((isActive) => !isActive);
          const newHighlights = [...prev];
          newHighlights[nextIndex] = true;
          return newHighlights;
        }
      });
    }, 500); // Change section every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const rotationAngle = 56; // degrees

  const polarToCartesian = (radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees + rotationAngle) - 90) * Math.PI / 180.0;
    return {
      x: 150 + (radius * Math.cos(angleInRadians)),
      y: 150 + (radius * Math.sin(angleInRadians))
    };
  };

  const createChevronPath = (innerRadius, outerRadius, startAngle, endAngle) => {
    const innerStart = polarToCartesian(innerRadius, startAngle);
    const innerEnd = polarToCartesian(innerRadius, endAngle);
    const outerStart = polarToCartesian(outerRadius, startAngle);
    const outerEnd = polarToCartesian(outerRadius, endAngle);
    
    const midRadius = (innerRadius + outerRadius) / 2;
    
    const startTipAngleOffset = -5;
    const endTipAngleOffset = 5;
    
    const startTip = polarToCartesian(midRadius, startAngle - startTipAngleOffset);
    const endTip = polarToCartesian(midRadius, endAngle + endTipAngleOffset);

    return [
      "M", startTip.x, startTip.y,
      "L", innerStart.x, innerStart.y,
      "A", innerRadius, innerRadius, 0, 0, 1, innerEnd.x, innerEnd.y,
      "L", endTip.x, endTip.y,
      "L", outerEnd.x, outerEnd.y,
      "A", outerRadius, outerRadius, 0, 0, 0, outerStart.x, outerStart.y,
      "Z"
    ].join(" ");
  };

  const createArcPath = (radius, startAngle, endAngle, clockwise = true) => {
    const start = polarToCartesian(radius, startAngle);
    const end = polarToCartesian(radius, endAngle);
    const largeArcFlag = Math.abs(endAngle - startAngle) <= 180 ? "0" : "1";
    const sweepFlag = clockwise ? "1" : "0";

    return [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, sweepFlag, end.x, end.y
    ].join(" ");
  };

  return (
    <svg viewBox="0 0 300 300" className="w-full h-full max-w-2xl mx-auto">
      <defs>
        {brightPastelColors10.map((color, index) => (
          <linearGradient key={`gradient${index}`} id={`gradient${index}`} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor={color[0]} />
            <stop offset="100%" stopColor={color[1]} />
          </linearGradient>
        ))}
      </defs>
      
      {/* Inner circle sections */}
      {sections.map((section, index) => {
        const startAngle = index * 72 - 90;
        const endAngle = startAngle + 65;
        const needsFlip = index === 2 || index === 3;
        const isActive = activeHighlights[index * 2];
        return (
          <g key={`inner-${index}`}>
            <path
              d={createChevronPath(82.5, 112.5, startAngle, endAngle)}
              fill={isActive ? `url(#gradient${index})` : neutralColor}
              stroke="#fff"
              strokeWidth="0.5"
              style={{ transition: 'fill 0.5s ease-in-out' }}
            />
            <path
              id={`innerPath${index}`}
              d={createArcPath(97.5, needsFlip ? endAngle : startAngle, needsFlip ? startAngle : endAngle, !needsFlip)}
              fill="none"
            />
            <text fontSize="6" fill="#232323" fontFamily="monospace" fontWeight="bold">
              <textPath href={`#innerPath${index}`} startOffset="50%" textAnchor="middle">
                {section.inner}
              </textPath>
            </text>
          </g>
        );
      })}

      {/* Outer circle sections */}
      {sections.map((section, index) => {
        const startAngle = index * 72 - 54;
        const endAngle = startAngle + 65;
        const needsFlip = index >= 1 && index <= 3;
        const isActive = activeHighlights[index * 2 + 1];
        return (
          <g key={`outer-${index}`}>
            <path
              d={createChevronPath(120, 142.5, startAngle, endAngle)}
              fill={isActive ? `url(#gradient${index})` : neutralColor}
              stroke="#fff"
              strokeWidth="0.5"
              style={{ transition: 'fill 0.5s ease-in-out' }}
            />
            <path
              id={`outerPath${index}`}
              d={createArcPath(131.25, needsFlip ? endAngle : startAngle, needsFlip ? startAngle : endAngle, !needsFlip)}
              fill="none"
            />
            <text fontSize="5.5" fill="#232323" fontFamily="monospace">
              <textPath href={`#outerPath${index}`} startOffset="50%" textAnchor="middle">
                {section.outer}
              </textPath>
            </text>
          </g>
        );
      })}

      {/* Central text */}
      <text x="150" y="150" textAnchor="middle" fontSize="12" fontWeight="bold" fill="#232323" fontFamily="monospace">
        SITA AI
      </text>
    </svg>
  );
};

export default SitaAIFlywheel;