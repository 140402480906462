import React, { useState, useEffect } from 'react';
import { Lock, ArrowLeft, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-2 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const Button = ({ children, variant = 'default', to, onClick, className = '' }) => {
  const baseStyle = "font-mono font-thin text-sm py-4 px-8 rounded-full transition-colors duration-300 w-64 sm:w-auto flex items-center justify-center h-14";
  const variantStyles = {
    default: "bg-[#353535] text-[#fff4e8] hover:bg-opacity-80",
    outline: "bg-transparent border border-[#353535] text-[#353535] hover:bg-[#353535] hover:text-[#fff4e8]",
  };

  const buttonContent = (
    <>
      {children}
      <ArrowIcon />
    </>
  );

  if (to) {
    return (
      <Link
        to={to}
        className={`${baseStyle} ${variantStyles[variant]} ${className}`}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <button
      className={`${baseStyle} ${variantStyles[variant]} ${className}`}
      onClick={onClick}
    >
      {buttonContent}
    </button>
  );
};

const DashboardItem = ({ title, active, onClick, locked }) => (
  <div
    onClick={locked ? null : onClick}
    className={`p-4 border-b cursor-pointer ${
      active ? 'bg-secondary/50' : ''
    } ${locked ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <div className="flex items-center">
      <span className="text-primary">{title}</span>
      {locked && <Lock size={16} className="ml-2 text-primary" />}
    </div>
  </div>
);

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState('Background');
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(true);
  const userName = "John"; // This should be dynamically set based on user input

  const dashboardItems = [
    { title: "Background", locked: false },
    { title: "Personality Summary", locked: false },
    { title: "Career Recommendations", locked: false },
    { title: "Final Summary", locked: false },
    { title: "Talk To Your Career Coach", locked: true },
    { title: "Recommended Courses", locked: true },
    { title: "Recommended Sessions", locked: true },
    { title: "Recommended Readings", locked: true },
    { title: "People To Follow", locked: true },
  ];

  useEffect(() => {
    // Set initial content visibility based on screen size
    const handleResize = () => {
      setIsMobileMenuVisible(window.innerWidth >= 768);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleItemClick = (title) => {
    setActiveSection(title);
    if (window.innerWidth < 768) {
      setIsMobileMenuVisible(false);
    }
  };

  const handleBackClick = () => {
    setIsMobileMenuVisible(true);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'Background':
        return (
          <div className="space-y-6">
            <div className="mb-4">
              <h1 className="text-3xl font-bold text-primary">{userName}'s Report</h1>
              <p className="text-sm text-primary/80">Software Engineer at Google, 16 Years of Experience</p>
            </div>
            <div className="bg-gradient-to-r from-secondary to-tertiary p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold mb-2 text-primary">{userName}'s Background</h2>
              <p className="text-primary/80">
                {userName} is a seasoned software engineer with a passion for innovative technologies.
                With 16 years of experience at Google, they have contributed to numerous high-impact projects,
                showcasing their expertise in algorithm design and scalable systems. Outside of work,
                {userName} is an avid rock climber and volunteers teaching coding to underprivileged youth.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gradient-to-r from-secondary to-tertiary p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-2 text-primary">Interests</h3>
                <ul className="list-disc list-inside text-primary/80">
                  <li>Machine Learning</li>
                  <li>Distributed Systems</li>
                  <li>Open Source Contributions</li>
                  <li>Rock Climbing</li>
                  <li>Mentoring</li>
                </ul>
              </div>
              <div className="bg-gradient-to-r from-secondary to-tertiary p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-2 text-primary">Hypothesis</h3>
                <p className="text-primary/80">
                  {userName}'s success stems from their ability to blend technical expertise
                  with strong interpersonal skills, enabling them to lead and inspire teams
                  while driving technological advancements.
                </p>
              </div>
              <div className="bg-gradient-to-r from-secondary to-tertiary p-4 rounded-lg shadow">
                <h3 className="font-semibold mb-2 text-primary">Career Goal</h3>
                <p className="text-primary/80">
                  To leverage extensive experience in software engineering to transition into
                  a Chief Technology Officer role, focusing on integrating cutting-edge AI
                  technologies to solve global challenges in education and healthcare sectors.
                </p>
              </div>
            </div>
          </div>
        );
      case 'Personality Summary':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold">Your ENFP Personality Type</h2>
            <p className="text-lg">Extraversion | Intuition | Feeling | Perceiving</p>
            <div className="space-y-4">
              {['Extraversion', 'Intuition', 'Feeling', 'Perceiving'].map((trait, index) => (
                <div key={index} className="relative">
                  <div className="flex justify-between text-sm mb-1">
                    <span>{trait}</span>
                    <span>{trait === 'Extraversion' ? 'Introversion' : 
                           trait === 'Intuition' ? 'Sensing' :
                           trait === 'Feeling' ? 'Thinking' : 'Judging'}</span>
                  </div>
                  <div className="h-2 bg-gradient-to-r from-gray-700 via-gray-300 to-gray-700 rounded-full">
                    <div 
                      className="h-full bg-blue-500 rounded-full" 
                      style={{width: `${[77, 93, 91, 96][index]}%`}}
                    ></div>
                  </div>
                  <div className="flex justify-between text-xs mt-1">
                    <span>VERY LIKELY</span>
                    <span>LIKELY</span>
                    <span>SOMEWHAT LIKELY</span>
                    <span>LIKELY</span>
                    <span>VERY LIKELY</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-gray-800 text-white p-4 rounded-lg">
              <p>Score: Extraversion | 77&nbsp;&nbsp;&nbsp;&nbsp;Intuition | 93&nbsp;&nbsp;&nbsp;&nbsp;Feeling | 91&nbsp;&nbsp;&nbsp;&nbsp;Perceiving | 96</p>
            </div>
            <div className="space-y-2">
              <div className="bg-gradient-to-r from-red-100 to-yellow-100 p-2 rounded">
                {userName} thrives in social situations, drawing energy from interactions with others.
              </div>
              <div className="bg-gradient-to-r from-green-100 to-blue-100 p-2 rounded">
                With a strong intuitive sense, {userName} excels at seeing patterns and possibilities.
              </div>
              <div className="bg-gradient-to-r from-purple-100 to-pink-100 p-2 rounded">
                {userName}'s decisions are often guided by personal values and the impact on others.
              </div>
              <div className="bg-gradient-to-r from-yellow-100 to-green-100 p-2 rounded">
                Adaptable and spontaneous, {userName} prefers flexibility over rigid structures.
              </div>
            </div>
          </div>
        );
        case 'Career Recommendations':
            return (
              <div className="grid grid-rows-3 gap-4 h-full">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gradient-to-r from-blue-100 to-green-100 p-4 rounded-lg shadow">
                    <h3 className="font-semibold mb-2">What You Love</h3>
                    <p>
                      {userName} has a passion for solving complex problems, working with cutting-edge technologies,
                      and collaborating with diverse teams. They thrive in environments that foster innovation
                      and allow for creative problem-solving. {userName} also enjoys mentoring others and
                      contributing to open-source projects.
                    </p>
                  </div>
                  <div className="bg-gradient-to-r from-yellow-100 to-red-100 p-4 rounded-lg shadow">
                    <h3 className="font-semibold mb-2">What You're Good At</h3>
                    <p>
                      With extensive experience in software engineering, {userName} excels in algorithm design,
                      system architecture, and code optimization. They have a knack for quickly grasping new
                      technologies and applying them effectively. {userName}'s strong communication skills
                      also make them adept at explaining complex concepts to non-technical stakeholders.
                    </p>
                  </div>
                </div>
                <div className="bg-gradient-to-r from-purple-100 to-pink-100 p-4 rounded-lg shadow">
                  <h3 className="font-semibold mb-2">Your Recommended Career Paths</h3>
                  <ul className="list-disc list-inside">
                    <li>Chief Technology Officer (CTO)</li>
                    <li>AI Research Scientist</li>
                    <li>Technical Project Manager</li>
                    <li>Software Architect</li>
                    <li>Technology Consultant</li>
                  </ul>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gradient-to-r from-green-100 to-blue-100 p-4 rounded-lg shadow">
                    <h3 className="font-semibold mb-2">What You Can Get Paid For</h3>
                    <p>
                      {userName}'s expertise in software engineering, particularly in areas like machine learning
                      and distributed systems, is highly valuable in the tech industry. They can command high
                      salaries for roles in software architecture, AI development, and technical leadership.
                      Their ability to bridge technical and business aspects also opens up opportunities in
                      technology consulting and executive positions.
                    </p>
                  </div>
                  <div className="bg-gradient-to-r from-red-100 to-yellow-100 p-4 rounded-lg shadow">
                    <h3 className="font-semibold mb-2">What the World Needs</h3>
                    <p>
                      The world needs innovative thinkers like {userName} who can apply advanced technologies
                      to solve global challenges. There's a growing demand for experts who can develop AI
                      solutions for healthcare, create scalable systems for education, and design sustainable
                      tech solutions. {userName}'s blend of technical skills and passion for social impact
                      positions them well to contribute to these crucial areas.
                    </p>
                  </div>
                </div>
              </div>
            );
          case 'Final Summary':    
            return (
              <div className="h-full flex flex-col">
                <div className="flex-grow flex flex-col space-y-4 mb-8">
                  <div className="bg-gradient-to-r from-blue-100 to-green-100 p-6 rounded-lg shadow flex-grow">
                    <h2 className="text-2xl font-semibold mb-4">Recommended For You</h2>
                    <p className="text-lg">
                      Based on your skills and interests, we recommend exploring roles in AI Research or
                      Technical Project Management. These fields align well with your background in software
                      engineering and your passion for innovation. Consider pursuing advanced certifications
                      in machine learning or project management methodologies to enhance your qualifications.
                    </p>
                  </div>
                  <div className="bg-gradient-to-r from-purple-100 to-pink-100 p-6 rounded-lg shadow flex-grow">
                    <h2 className="text-2xl font-semibold mb-4">Conclusion</h2>
                    <p className="text-lg">
                      Your journey in tech has equipped you with valuable skills and experiences. To progress
                      in your career, focus on leveraging your strengths in problem-solving and leadership.
                      Consider taking on more cross-functional projects to broaden your expertise. Remember,
                      continuous learning is key in the fast-paced tech industry. Stay curious, seek mentorship,
                      and don't hesitate to take calculated risks in your career path.
                    </p>
                  </div>
                </div>
                <div className="mt-4 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                  <div className="text-center">
                    <p className="text-sm mb-2">Want more help? Talk to an expert!</p>
                    <Button variant="default">Book A Call With A Mentor</Button>
                  </div>
                  <div className="text-center">
                    <p className="text-sm mb-2">Not satisfied with your result?</p>
                    <Button variant="outline" to="/discovery">Let's Reevaluate</Button>
                  </div>
                </div>
              </div>
            );
          default:
            return <div className="text-primary">Content not available</div>;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-secondary to-tertiary py-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-lg shadow-xl overflow-hidden floating-element">
          <div className="flex flex-col md:flex-row relative">
            <div className={`w-full md:w-1/4 bg-secondary bg-opacity-50 ${isMobileMenuVisible ? 'block' : 'hidden md:block'}`}>
              {dashboardItems.map((item, index) => (
                <DashboardItem
                  key={index}
                  title={item.title}
                  active={activeSection === item.title}
                  onClick={() => handleItemClick(item.title)}
                  locked={item.locked}
                />
              ))}
            </div>
            <div className={`w-full md:w-3/4 p-6 bg-white bg-opacity-80 ${isMobileMenuVisible ? 'hidden md:block' : 'block'}`}>
              {!isMobileMenuVisible && (
                <button
                  onClick={handleBackClick}
                  className="md:hidden mb-4 flex items-center text-primary"
                >
                  <ArrowLeft size={20} className="mr-2" />
                  Back to Menu
                </button>
              )}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;