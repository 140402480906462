import React from 'react';

const GradientWaves = () => {
  return (
    <svg
      className="gradient-waves"
      preserveAspectRatio="none"
      viewBox="0 0 2880 560"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        width: '200%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <defs>
        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#E0F7FA" /> // Light cyan (representing fresh starts)
            <stop offset="100%" stopColor="#B2DFDB" /> // Soft teal (symbolizing growth)
        </linearGradient>
        <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#E8EAF6" /> // Soft indigo (suggesting professionalism)
            <stop offset="100%" stopColor="#C5CAE9" /> // Light periwinkle (implying progress)
        </linearGradient>
      </defs>
      <path
        d="M0,280 C320,380,420,280,720,280 C1020,280,1120,380,1440,280 C1760,380,1860,280,2160,280 C2460,280,2560,380,2880,280 V560 H0 V280 Z"
        fill="url(#gradient1)"
        className="wave wave1"
      />
      <path
        d="M0,280 C320,180,480,380,720,280 C960,180,1120,380,1440,280 C1760,180,1920,380,2160,280 C2400,180,2560,380,2880,280 V560 H0 V280 Z"
        fill="url(#gradient2)"
        className="wave wave2"
      />
    </svg>
  );
};

export default GradientWaves;