import React from 'react';
import { useSpring, animated } from 'react-spring';

const AnimatedCounter = ({ end, duration = 2000 }) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: end },
    config: { duration },
  });

  return (
    <animated.span>
      {number.interpolate(n => Math.floor(n).toLocaleString())}
    </animated.span>
  );
};

export default AnimatedCounter;