import React, { useState } from 'react';
import ProductCard from './ProductCard';

// Import your background images
import bgImage1 from '../assets/images/woman2.jpg';
import bgImage2 from '../assets/images/man1.jpg';
import bgImage3 from '../assets/images/woman1.jpg';
import bgImage4 from '../assets/images/man3.jpg';

const UpcomingProducts = () => {
    const [selectedProduct, setSelectedProduct] = useState('MULTIPLE LANGUAGES');

    const products = [
        {
          icon: 'globe',
          title: 'MULTIPLE LANGUAGES',
          subtitle: 'Your career. Your language.',
          body: 'Access SITA in multiple regional languages, making it easy and personalised for you.',
          color: 'bg-[#F0F8FF]',
          abstractType: 'languages',
          imagePosition: 'low',
          bgImage: bgImage1
        },
        {
          icon: 'smartphone',
          title: 'AVAILABLE ON WHATSAPP',
          subtitle: 'No need to download another app.',
          body: 'Talk to SITA whenever you need her.',
          color: 'bg-[#F0FFF8]',
          abstractType: 'whatsapp',
          imagePosition: 'up',
          bgImage: bgImage2
        },
        {
          icon: 'users',
          title: 'VALUABLE CONNECTIONS',
          subtitle: 'For aspirant and recruiters alike.',
          body: 'Whether you are building your career or finding top talent, SITA is made for all.',
          color: 'bg-[#FFF8F0]',
          abstractType: 'connections',
          imagePosition: 'low',
          bgImage: bgImage3
        },
        {
          icon: 'briefcase',
          title: 'CAREER LIFECYCLE SUPPORT',
          subtitle: 'Your career coach for life',
          body: 'From exploration to employment, SITA fulfills all your wishes.',
          color: 'bg-[#F8F0FF]',
          abstractType: 'lifecycle',
          imagePosition: 'up',
          bgImage: bgImage4
        }
    ];
  
    const handleProductSelect = (title) => {
        setSelectedProduct(title);
    };

    return (
        <section className="py-16 bg-[#fff4e8]">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="overflow-x-auto pb-8">
                <div className="flex space-x-6 min-w-max">
                    {products.map((product, index) => (
                    <div key={index} className="w-80 md:w-96 flex-shrink-0" onClick={() => handleProductSelect(product.title)}>
                        <ProductCard {...product} />
                    </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    );
};

export default UpcomingProducts;