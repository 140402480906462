import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AnimatedGraph from './AnimatedGraph';
import AnimatedCounter from './AnimatedCounter';
import ParticleBackground from './ParticleBackground';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-2 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const AnimatedText = ({ children }) => (
  <motion.div
    animate={{
      scale: [1, 1.05, 1],
      opacity: [0.8, 1, 0.8],
    }}
    transition={{
      duration: 5,
      repeat: Infinity,
      repeatType: "reverse"
    }}
  >
    {children}
  </motion.div>
);

const EmbedComponent = () => {
  const data = [
    { year: '2024', interviews: 0 },
    { year: '2025', interviews: 600000 },
    { year: '2026', interviews: 1000000 },
  ];

  return (
    <section className="relative my-8 sm:my-12 md:my-16 mx-4 sm:mx-6 md:mx-8 lg:mx-16">
      <h2 className="text-base sm:text-lg md:text-xl text-center mb-4 sm:mb-6 md:mb-8 leading-[1.1]">UNLOCK YOUR POTENTIAL</h2>
      <div className="rounded-3xl overflow-hidden shadow-2xl transform hover:scale-[1.01] transition-all duration-300 flex flex-col md:flex-row relative min-h-[450px] md:min-h-[500px]" style={{
        background: 'linear-gradient(135deg, #1a2a3a 0%, #0a1a2a 100%)'
      }}>
        <div className="md:w-1/2 relative py-10 md:py-0">
          <ParticleBackground />
          <div className="p-5 sm:p-6 md:p-12 lg:p-16 flex flex-col justify-center text-white relative z-10 text-center md:text-right h-full">
            <h2 className="text-3xl sm:text-4xl md:text-6xl lg:text-7xl font-extrabold mb-3 sm:mb-4 leading-tight">
              Self Discovery
            </h2>
            <h3 className="text-xl sm:text-2xl md:text-4xl lg:text-5xl font-light mb-6 sm:mb-8 md:mb-12">
              Find What Excites You
            </h3>
            <div className="mb-6 sm:mb-8 flex justify-center md:justify-end">
              <AnimatedCounter end={1000000} /> Interviews Analyzed
            </div>
            <div className="flex justify-center md:justify-end">
              <Link 
                to="/discovery" 
                className="bg-blue-500 text-[#fff4e8] font-mono font-thin text-xs sm:text-sm py-2 sm:py-3 px-4 sm:px-6 rounded-full hover:bg-opacity-80 transition-colors duration-300 w-40 sm:w-48 md:w-64 inline-flex items-center justify-center h-10 sm:h-12"
              >
                Explore Now
                <ArrowIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 relative overflow-hidden bg-[#1a2a3a] flex items-center py-10 md:py-0">
          <div className="absolute inset-0">
            <AnimatedGraph data={data} />
          </div>
          <div className="relative z-10 h-full flex flex-col justify-center p-5 sm:p-6 md:p-12">
            <div className="text-gray-300 text-center relative">
              <div className="absolute inset-0 bg-gradient-to-br from-black/10 to-black/5 backdrop-blur-[1px] rounded-xl"></div>
              <div className="p-3 md:p-6 relative z-10">
                <AnimatedText>
                  <p className="text-[36px] sm:text-[48px] md:text-[72px] font-bold mb-4 sm:mb-6 leading-tight" style={{
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                  }}>
                    1 Million
                  </p>
                </AnimatedText>
                <p className="text-[16px] sm:text-[20px] md:text-[30px] font-bold mb-4 sm:mb-6 leading-tight" style={{
                  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                }}>
                  Training on 1 million assessment interviews
                </p>
                <p className="text-[14px] sm:text-[18px] md:text-[24px] leading-relaxed" style={{
                  textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                }}>
                  to help you discover your true passions and strengths. Discover your passion with insights from over 10,000 career paths.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmbedComponent;