import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-1 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="h-8"></div>
      <nav className={`fixed top-4 left-4 right-4 z-50 transition-all duration-300 bg-[#fff4e8] bg-opacity-60 backdrop-blur-sm rounded-full shadow-lg ${isScrolled ? 'top-2' : 'top-4'}`}>
        <div className="max-w-7xl mx-auto px-6 py-4">
          {/* Desktop Layout */}
          <div className="hidden md:flex items-center justify-between">
            <div className="flex space-x-6 font-mono text-sm text-[#353535]">
              <Link to="/about" className="py-2">About sita</Link>
              <a href="/coming-soon" className="py-2">Colleges</a>
            </div>
            <Link to="/" className="text-2xl font-bold">sita</Link>
            <div className="flex space-x-6 font-mono text-sm text-[#353535] items-center">
              <a href="https://hiroscope.ai/" target="_blank" rel="noopener noreferrer" className="bg-[#353535] text-[#fff4e8] font-thin py-2 px-4 rounded-full hover:bg-opacity-80 transition-colors duration-300 flex items-center">
                Recruiters
                <ArrowIcon />
              </a>
              <a href="/contact" className="py-2">Contact</a>
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="flex md:hidden items-center justify-between">
            <Link to="/" className="text-2xl font-bold">sita</Link>
            <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-[#fff4e8] bg-opacity-60 backdrop-blur-sm mt-2 rounded-3xl shadow-lg">
            <div className="px-6 py-4 flex flex-col space-y-4 font-mono text-sm text-[#353535]">
              <Link to="/about" className="py-2">About sita</Link>
              <a href="/coming-soon" className="py-2">Colleges</a>
              <div className="flex items-center">
                <button onClick={() => window.location.href='#recruiters'} className="bg-[#353535] text-[#fff4e8] font-thin py-2 px-4 rounded-full hover:bg-opacity-80 transition-colors duration-300 flex items-center text-sm">
                  Recruiters
                  <ArrowIcon />
                </button>
              </div>
              <a href="/contact" className="py-2">Contact</a>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;