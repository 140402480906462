import React from 'react';
import { Link } from 'react-router-dom';
import GradientWaves from './GradientWaves';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-2 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const Hero = () => {
  return (
    <section className="min-h-[70vh] flex items-center justify-center px-4 sm:px-6 lg:px-8 pt-24 pb-12 md:pt-32 md:pb-16 relative overflow-hidden">
      <GradientWaves />
      <div className="text-center max-w-3xl mx-auto relative z-10">
        <h1 className="hero-heading text-4xl sm:text-5xl lg:text-6xl font-medium mb-6">
          Multimodal AI Revolutionizing Career Growth
        </h1>
        <h2 className="text-xl sm:text-2xl font-thin mb-12">
          Career growth strategies tailored to your unique strengths and market needs by AI
        </h2>
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
        <Link 
            to="/warmup" 
            className="bg-[#353535] text-[#fff4e8] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-opacity-80 transition-colors duration-300 w-64 sm:w-auto flex items-center justify-center h-14"
          >
            Prepare Yourself
            <ArrowIcon />
          </Link>
          <Link 
            to="/discovery" 
            className="bg-transparent border border-[#353535] text-[#353535] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300 w-64 sm:w-auto flex items-center justify-center h-14"
          >
            Discover Yourself
            <ArrowIcon />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;