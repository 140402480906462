import React, { useEffect, useRef } from 'react';

const RefinedNetworkVisualization = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const gridSize = 8;
    const nodes = [];

    // Create a grid of nodes
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        nodes.push({
          x: (canvas.width / (gridSize - 1)) * i,
          y: (canvas.height / (gridSize - 1)) * j,
          originalX: (canvas.width / (gridSize - 1)) * i,
          originalY: (canvas.height / (gridSize - 1)) * j,
          vx: 0,
          vy: 0,
          highlighted: false,
          highlightDuration: 0,
        });
      }
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Move nodes and handle highlighting
      nodes.forEach(node => {
        const dx = node.x - node.originalX;
        const dy = node.y - node.originalY;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        // Apply a force to return to original position
        const force = distance * 0.008; // Reduced force for more distortion
        const angle = Math.atan2(dy, dx);
        
        node.vx -= Math.cos(angle) * force;
        node.vy -= Math.sin(angle) * force;
        
        // Apply some random movement
        node.vx += (Math.random() - 0.5) * 0.8; // Increased random movement
        node.vy += (Math.random() - 0.5) * 0.8;
        
        // Damping
        node.vx *= 0.97;
        node.vy *= 0.97;
        
        // Update position
        node.x += node.vx;
        node.y += node.vy;

        // Random highlighting
        if (Math.random() < 0.001) {
          node.highlighted = true;
          node.highlightDuration = 60; // Highlight for 60 frames
        }
        if (node.highlightDuration > 0) {
          node.highlightDuration--;
        } else {
          node.highlighted = false;
        }
      });

      // Draw connections
      ctx.beginPath();
      nodes.forEach((node, i) => {
        nodes.forEach((otherNode, j) => {
          if (i < j) {
            const distance = Math.hypot(node.x - otherNode.x, node.y - otherNode.y);
            if (distance < canvas.width / 3) { // Increased connection distance
              ctx.moveTo(node.x, node.y);
              ctx.lineTo(otherNode.x, otherNode.y);
            }
          }
        });
      });
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.15)';
      ctx.stroke();

      // Draw nodes
      nodes.forEach(node => {
        ctx.beginPath();
        ctx.arc(node.x, node.y, node.highlighted ? 4 : 2, 0, Math.PI * 2);
        ctx.fillStyle = node.highlighted 
          ? `rgba(255, 255, 255, ${0.7 + 0.3 * Math.sin(Date.now() / 200)})` // Pulsating effect
          : 'rgba(255, 255, 255, 0.5)';
        ctx.fill();
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} className="absolute inset-0 w-full h-full" />;
};

export default RefinedNetworkVisualization;