import React from 'react';

const ComingSoon = () => {
  return (
    <div className="flex flex-col h-[calc(100vh-100px)]"> {/* Adjust 100px to match your header height */}
      <div className="flex-grow flex items-center justify-center">
        <div className="container mx-auto px-4 max-w-4xl">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl font-bold mb-6 text-gray-900">Coming Soon</h1>
            <div className="w-16 h-1 bg-blue-500 mx-auto mb-8"></div>
            <p className="text-xl sm:text-2xl mb-8 text-gray-700">
              We're working hard to make this section live soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;