import React from 'react';
import { Link } from 'react-router-dom';
import AnimatedCounter from './AnimatedCounter';
import ParticleBackground from './ParticleBackground';
import NetworkVisualization from './NetworkVisualization';
import RefinedNetworkVisualization from './RefinedNetworkVisualization';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-2 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const IntelligentMatchingComponent = () => {
    return (
      <section className="relative my-8 sm:my-12 md:my-16 mx-4 sm:mx-6 md:mx-8 lg:mx-16">
        <div className="rounded-3xl overflow-hidden shadow-2xl transform hover:scale-[1.01] transition-all duration-300 flex flex-col md:flex-row relative min-h-[500px]" style={{
          background: 'linear-gradient(135deg, #1a2a3a 0%, #0a1a2a 100%)'
        }}>
        <div className="md:w-1/2 p-8 md:p-12 lg:p-16 flex flex-col justify-center text-white relative z-10 py-16 md:py-0">
          <div className="text-center md:text-right">
            <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold mb-6 md:mb-4 leading-tight text-gray-100">
              Intelligent Matching
            </h2>
            <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light mb-8 md:mb-6 text-gray-300">
              Connect & elevate
            </h3>
            <div className="mb-8 md:mb-6 text-gray-300">
              <AnimatedCounter end={10000} /> Career Paths
            </div>
            <div className="flex justify-center md:justify-end mt-8 md:mt-2">
              <Link 
                to="/coming-soon" 
                className="bg-blue-500 text-[#fff4e8] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-opacity-80 transition-colors duration-300 w-56 md:w-64 inline-flex items-center justify-center h-14"
              >
                Explore Now
                <ArrowIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 flex flex-col relative z-10" style={{
            background: 'linear-gradient(135deg, #20303f 0%, #10202f 100%)'
            }}>
            <div className="absolute inset-0">
                <RefinedNetworkVisualization />
            </div>
            <div className="relative z-10 flex flex-col justify-center text-center h-full p-8 md:p-12 lg:p-16 py-16 md:py-0">
                <p className="text-gray-300 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight mb-8 md:mb-6">
                Get connected to the right people, hear podcasts that improve your skills, and attend growth-focused sessions.
                </p>
                <p className="text-gray-300 text-lg sm:text-xl md:text-2xl lg:text-3xl leading-tight">
                Connect, learn, and grow together with peers and your idols.
                </p>
            </div>
        </div>
      </div>
    </section>
  );
};

export default IntelligentMatchingComponent;