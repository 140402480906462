import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const AnimatedGraph = ({ data }) => {
  return (
    <div style={{ width: '100%', height: '100%', background: '#1a2a3a' }}>
      <ResponsiveContainer>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#555" />
          <XAxis dataKey="year" stroke="#888" />
          <YAxis stroke="#888" tickFormatter={(value) => `${value / 1000}k`} />
          <Line 
            type="monotone" 
            dataKey="interviews" 
            stroke="#888"  // Changed to grey
            strokeWidth={1}  // Made thinner
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AnimatedGraph;