import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Search, Clock, BookOpen, Loader } from 'lucide-react';

export const JobDescriptionInput = ({ jobDescription, setJobDescription, isRequired }) => (
    <div className="mb-6">
      <label className="block text-sm font-medium text-[#353535] mb-2">
        Job Description {isRequired && <span className="text-red-500">*</span>}
      </label>
      <textarea
        value={jobDescription}
        onChange={(e) => setJobDescription(e.target.value)}
        className="w-full px-4 py-3 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300"
        rows="4"
        placeholder="Paste your job description here"
      ></textarea>
    </div>
  );
  
export const ExperienceSelector = ({ experience, setExperience, isRequired }) => (
    <div>
      <label className="block text-sm font-medium text-[#353535] mb-2">
        Years of Experience {isRequired && <span className="text-red-500">*</span>}
      </label>
      <select
        value={experience}
        onChange={(e) => setExperience(e.target.value)}
        className="w-full px-3 py-2 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300"
      >
        <option value="">Select years of experience</option>
        {[...Array(16).keys()].map(year => (
          <option key={year} value={year}>{year === 15 ? '15+' : year}</option>
        ))}
      </select>
    </div>
);
  
export const TimeLimitSelector = ({ timeLimit, setTimeLimit, isRequired }) => (
    <div>
      <label className="block text-sm font-medium text-[#353535] mb-2">
        Select Time Limit {isRequired && <span className="text-red-500">*</span>}
      </label>
      <select
        value={timeLimit}
        onChange={(e) => setTimeLimit(e.target.value)}
        className="w-full px-3 py-2 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300"
      >
        <option value="">Select time limit</option>
        {[15, 30, 45, 60, 75, 90].map(time => (
          <option key={time} value={time}>{time} mins</option>
        ))}
      </select>
    </div>
);
  
export const InterviewQuestions = ({ questions, onAnswerSubmit, onComplete, isLoading }) => {
    const [currentAnswers, setCurrentAnswers] = useState({});
    const [emptyAnswers, setEmptyAnswers] = useState([]);
    const answerRefs = useRef([]);
  
    const handleAnswerChange = (index, answer) => {
      setCurrentAnswers(prev => ({ ...prev, [index]: answer }));
      onAnswerSubmit(index, answer);
    };
  
    const handleComplete = async () => {
      const emptyIndices = questions.reduce((acc, _, index) => {
        if (!currentAnswers[index] || currentAnswers[index].trim() === '') {
          acc.push(index);
        }
        return acc;
      }, []);
  
      if (emptyIndices.length > 0) {
        setEmptyAnswers(emptyIndices);
        answerRefs.current[emptyIndices[0]].scrollIntoView({ behavior: 'smooth' });
      } else {
        await onComplete(Object.values(currentAnswers));
      }
    };
  
    return (
      <div>
        {questions.map((question, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="mb-6"
          >
            <p className="font-medium mb-2 text-[#353535]">
              {question} <span className="text-red-500">*</span>
            </p>
            <motion.textarea
              ref={el => answerRefs.current[index] = el}
              value={currentAnswers[index] || ''}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              className={`w-full px-4 py-3 text-[#353535] bg-[#F7F2ED] border-2 ${
                emptyAnswers.includes(index) ? 'border-red-500' : 'border-[#353535]'
              } rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300`}
              rows="4"
              placeholder="Type your answer here"
              animate={emptyAnswers.includes(index) ? { x: [-10, 10, -10, 10, 0] } : {}}
              transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            ></motion.textarea>
          </motion.div>
        ))}
        <motion.button
          onClick={handleComplete}
          className="w-full bg-[#353535] text-[#fff4e8] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-opacity-80 transition-colors duration-300 mt-8 flex items-center justify-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader className="animate-spin mr-2" size={16} />
              Processing...
            </>
          ) : (
            <>
              Complete Interview
              <ChevronDown className="ml-2" size={16} />
            </>
          )}
        </motion.button>
      </div>
    );
};

export const ResultModal = ({ score, review, onClose }) => (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="bg-white rounded-3xl shadow-xl p-8 max-w-md w-full mx-4"
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="text-2xl font-bold text-[#353535] mb-4">
            Your Interview Score
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            Based on your responses, your average interview score is:
          </p>
          <p className="text-6xl font-bold text-[#353535] mb-4 text-center">
            {score !== null ? `${score}/100` : 'N/A'}
          </p>
          <p className="text-sm text-gray-600 mb-8 text-center italic">
            "{review}"
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="w-full bg-[#353535] text-[#fff4e8] font-mono font-thin text-sm py-3 rounded-full hover:bg-opacity-80 transition-colors duration-300"
            onClick={onClose}
          >
            Close
          </motion.button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );