import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, Trophy, Star, ArrowUpCircle, ArrowDownCircle, ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import threeDGraphImage from '../assets/images/3dgraph.png';

// Mock data generation
const states = ['Maharashtra', 'Karnataka', 'Tamil Nadu', 'Delhi', 'West Bengal'];
const cities = {
  Maharashtra: ['Mumbai', 'Pune', 'Nagpur', 'Nashik', 'Aurangabad'],
  Karnataka: ['Bangalore', 'Mysore', 'Hubli', 'Mangalore', 'Belgaum'],
  'Tamil Nadu': ['Chennai', 'Coimbatore', 'Madurai', 'Trichy', 'Salem'],
  Delhi: ['New Delhi', 'Gurgaon', 'Noida', 'Faridabad', 'Ghaziabad'],
  'West Bengal': ['Kolkata', 'Howrah', 'Durgapur', 'Asansol', 'Siliguri']
};

const colleges = {
  Mumbai: ['IIT Bombay', 'St. Xavier\'s College'],
  Pune: ['Fergusson College', 'Symbiosis Institute of Technology'],
  Bangalore: ['Indian Institute of Science', 'RVCE'],
  Mysore: ['JSS Science and Technology University', 'SJCE'],
  Chennai: ['IIT Madras', 'Anna University'],
  Coimbatore: ['PSG College of Technology', 'Amrita Vishwa Vidyapeetham'],
  'New Delhi': ['IIT Delhi', 'Delhi University'],
  Gurgaon: ['NIT Delhi', 'Amity University'],
  Kolkata: ['IIT Kharagpur', 'Jadavpur University'],
  Howrah: ['Indian Institute of Engineering Science and Technology', 'Heritage Institute of Technology']
};

const skills = ['JavaScript', 'Python', 'Java', 'C++', 'React', 'Node.js', 'SQL', 'Machine Learning'];
const industries = ['Consumer Tech', 'Fintech', 'D2C', 'AgriTech', 'EdTech', 'Climate'];
const traits = ['Clarity of thoughts', 'Communication skills', 'Team work', 'Leadership'];

const generateRandomScore = () => Math.floor(Math.random() * 101);

const generateStudentData = () => {
  const data = [];
  let id = 1;

  states.forEach(state => {
    cities[state].forEach(city => {
      colleges[city]?.forEach(college => {
        for (let i = 0; i < 5; i++) {
          const firstName = ['Aarav', 'Aditi', 'Arjun', 'Diya', 'Ishaan', 'Kavya', 'Neha', 'Rohan', 'Sanya', 'Vihaan'][Math.floor(Math.random() * 10)];
          const lastName = ['Patel', 'Sharma', 'Singh', 'Reddy', 'Kumar', 'Gupta', 'Joshi', 'Mehta', 'Verma', 'Malhotra'][Math.floor(Math.random() * 10)];
          
          const student = {
            id: id++,
            name: `${firstName} ${lastName}`,
            college,
            city,
            state,
            skills: Object.fromEntries(skills.map(skill => [skill, generateRandomScore()])),
            industries: Object.fromEntries(industries.map(industry => [industry, generateRandomScore()])),
            traits: Object.fromEntries(traits.map(trait => [trait, generateRandomScore()]))
          };
          data.push(student);
        }
      });
    });
  });

  return data;
};

const generateHistoricalData = (student) => {
  const months = ['July 2024', 'August 2024', 'September 2024'];
  return months.map(month => ({
    month,
    ...Object.fromEntries(
      [...skills, ...traits, ...industries].map(item => [item, generateRandomScore()])
    )
  }));
};

const LeaderboardComponent = () => {
    const [students, setStudents] = useState(generateStudentData());
    const [selectedSkill, setSelectedSkill] = useState(skills[0]);
    const [selectedCollege, setSelectedCollege] = useState('All');
    const [selectedCity, setSelectedCity] = useState('All');
    const [selectedState, setSelectedState] = useState('All');
    const [selectedTrait, setSelectedTrait] = useState('Communication skills');
    const [selectedIndustry, setSelectedIndustry] = useState('Consumer Tech');
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const studentsPerPage = 25;
    const [is3DView, setIs3DView] = useState(false);

    const colors = {
        skill: '#8884d8',
        'Clarity of thoughts': '#82ca9d',
        'Communication skills': '#ffc658',
        'Team work': '#ff7300',
        Leadership: '#0088fe',
        industry: '#8dd1e1'
    };

    const [columnWidths, setColumnWidths] = useState({
        rank: 60,
        name: 130,
        college: 150,  // Reduced from 200 to 150
        city: 100,     // Slightly reduced
        state: 100,    // Slightly reduced
        skill: 80,     // Slightly reduced
        trait: 100,    // Slightly reduced
        industry: 100, // Slightly reduced
      });
    
      const [traitOpacity, setTraitOpacity] = useState(0.2);  // New state for trait line opacity
      const solidLineOpacity = 1;  // Constant opacity for skill and industry lines

      const dragStartX = useRef(null);
      const draggedColumn = useRef(null);
    
      const handleDragStart = (e, column) => {
        dragStartX.current = e.clientX;
        draggedColumn.current = column;
      };
    
      const handleDrag = (e) => {
        if (draggedColumn.current && dragStartX.current !== null) {
          const diffX = e.clientX - dragStartX.current;
          setColumnWidths(prev => ({
            ...prev,
            [draggedColumn.current]: Math.max(50, prev[draggedColumn.current] + diffX)
          }));
          dragStartX.current = e.clientX;
        }
      };
    
      const handleDragEnd = () => {
        dragStartX.current = null;
        draggedColumn.current = null;
      };
    
      useEffect(() => {
        const handleMouseMove = (e) => {
          if (draggedColumn.current) {
            handleDrag(e);
          }
        };
    
        const handleMouseUp = () => {
          handleDragEnd();
        };
    
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    
        return () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };
      }, []);    
  
    useEffect(() => {
      const interval = setInterval(() => {
        setStudents(prevStudents => {
          const updatedStudents = [...prevStudents];
          const randomIndex = Math.floor(Math.random() * updatedStudents.length);
          const randomSkill = skills[Math.floor(Math.random() * skills.length)];
          
          updatedStudents[randomIndex] = {
            ...updatedStudents[randomIndex],
            skills: {
              ...updatedStudents[randomIndex].skills,
              [randomSkill]: generateRandomScore()
            }
          };
          
          return updatedStudents;
        });
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const filteredStudents = students
      .filter(student => 
        (selectedCollege === 'All' || student.college === selectedCollege) &&
        (selectedCity === 'All' || student.city === selectedCity) &&
        (selectedState === 'All' || student.state === selectedState)
      )
      .sort((a, b) => b.skills[selectedSkill] - a.skills[selectedSkill]);
  
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);
  
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
    const handleStudentClick = (student) => {
      setSelectedStudent({
        ...student,
        historicalData: generateHistoricalData(student)
      });
    };
  
    const handleBackToLeaderboard = () => {
      setSelectedStudent(null);
    };
  
    const uniqueColleges = ['All', ...new Set(students.map(s => s.college))];
    const uniqueCities = ['All', ...new Set(students.map(s => s.city))];
    const uniqueStates = ['All', ...new Set(students.map(s => s.state))];

    return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#fff4e8] p-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-6xl"
      >
        <h1 className="text-4xl font-bold mb-8 text-center text-[#353535]">Interview Arena Leaderboard</h1>
        
        <div className="flex justify-end mb-4">
          <button
            onClick={() => setIs3DView(!is3DView)}
            className="px-4 py-2 bg-[#353535] text-[#fff4e8] rounded-full hover:bg-opacity-80 transition-colors duration-300"
          >
            Toggle {is3DView ? '2D' : '3D'} View
          </button>
        </div>

        {!selectedStudent ? (
              <motion.div 
                className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-8 transform transition-all duration-300 hover:scale-105 border border-white border-opacity-20"
                whileHover={{ boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)" }}
              >
                <div className="flex flex-wrap justify-between mb-4">
                  <div className="flex items-center mb-2 sm:mb-0">
                    <label className="mr-2 font-medium text-[#353535] text-sm">Skill:</label>
                    <select
                      value={selectedSkill}
                      onChange={(e) => setSelectedSkill(e.target.value)}
                      className="px-3 py-2 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300 text-sm font-mono font-thin"
                    >
                      {skills.map(skill => (
                        <option key={skill} value={skill}>{skill}</option>
                      ))}
                    </select>
                  </div>
                </div>
                
                <div className="overflow-x-auto">
                  <table className="w-full table-auto text-xs font-mono font-thin">
                    <thead>
                      <tr className="bg-[#353535] text-[#fff4e8]">
                        {Object.entries(columnWidths).map(([column, width]) => (
                          <th 
                            key={column} 
                            className="px-2 py-2 relative" 
                            style={{ width: `${width}px`, minWidth: `${width}px` }}
                          >
                            {column === 'rank' ? '#' : (
                              <select
                                value={column === 'trait' ? selectedTrait : (column === 'industry' ? selectedIndustry : `All`)}
                                onChange={(e) => {
                                  if (column === 'trait') setSelectedTrait(e.target.value);
                                  else if (column === 'industry') setSelectedIndustry(e.target.value);
                                  else if (column === 'college') setSelectedCollege(e.target.value);
                                  else if (column === 'city') setSelectedCity(e.target.value);
                                  else if (column === 'state') setSelectedState(e.target.value);
                                }}
                                className="bg-[#353535] text-[#fff4e8] border-none focus:outline-none w-full"
                              >
                                {column === 'trait' ? (
                                  traits.map(trait => (
                                    <option key={trait} value={trait}>{trait}</option>
                                  ))
                                ) : column === 'industry' ? (
                                  industries.map(industry => (
                                    <option key={industry} value={industry}>{industry}</option>
                                  ))
                                ) : (
                                  <>
                                    <option value="All">{column.charAt(0).toUpperCase() + column.slice(1)}</option>
                                    {(column === 'college' ? uniqueColleges : 
                                      column === 'city' ? uniqueCities : 
                                      column === 'state' ? uniqueStates : []).map(item => (
                                      <option key={item} value={item}>{item}</option>
                                    ))}
                                  </>
                                )}
                              </select>
                            )}
                            <div
                              className="absolute top-0 right-0 bottom-0 w-1 cursor-col-resize bg-[#fff4e8] opacity-0 hover:opacity-100"
                              onMouseDown={(e) => handleDragStart(e, column)}
                            />
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentStudents.map((student, index) => (
                        <motion.tr 
                          key={student.id}
                          className={index % 2 === 0 ? 'bg-[#F7F2ED]' : 'bg-white'}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.5, delay: index * 0.05 }}
                        >
                          <td className="px-2 py-2 text-center" style={{ width: `${columnWidths.rank}px`, minWidth: `${columnWidths.rank}px` }}>
                            {indexOfFirstStudent + index + 1}
                            {indexOfFirstStudent + index < 3 && (
                              <span className="ml-1">
                                {indexOfFirstStudent + index === 0 ? <Trophy className="inline-block text-yellow-500" size={12} /> :
                                 indexOfFirstStudent + index === 1 ? <Star className="inline-block text-gray-500" size={12} /> :
                                 <Star className="inline-block text-amber-600" size={12} />}
                              </span>
                            )}
                          </td>
                          <td className="px-2 py-2 truncate" style={{ width: `${columnWidths.name}px`, minWidth: `${columnWidths.name}px` }}>
                            <button
                              onClick={() => handleStudentClick(student)}
                              className="text-blue-600 hover:underline focus:outline-none"
                            >
                              {student.name}
                            </button>
                          </td>
                          <td className="px-2 py-2 truncate" style={{ width: `${columnWidths.college}px`, minWidth: `${columnWidths.college}px` }}>{student.college}</td>
                          <td className="px-2 py-2 truncate" style={{ width: `${columnWidths.city}px`, minWidth: `${columnWidths.city}px` }}>{student.city}</td>
                          <td className="px-2 py-2 truncate" style={{ width: `${columnWidths.state}px`, minWidth: `${columnWidths.state}px` }}>{student.state}</td>
                          <td className="px-2 py-2" style={{ width: `${columnWidths.skill}px`, minWidth: `${columnWidths.skill}px` }}>
                            {student.skills[selectedSkill]}
                            <span className="ml-1">
                              {student.skills[selectedSkill] > 50 ? 
                                <ArrowUpCircle className="inline-block text-green-500" size={12} /> : 
                                <ArrowDownCircle className="inline-block text-red-500" size={12} />}
                            </span>
                          </td>
                          <td className="px-2 py-2" style={{ width: `${columnWidths.trait}px`, minWidth: `${columnWidths.trait}px` }}>{student.traits[selectedTrait]}</td>
                          <td className="px-2 py-2" style={{ width: `${columnWidths.industry}px`, minWidth: `${columnWidths.industry}px` }}>{student.industries[selectedIndustry]}</td>
                        </motion.tr>
                      ))}
                    </tbody>
                  </table>
                </div>
    
                <div className="mt-4 flex justify-center text-sm">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="mr-2 px-4 py-2 bg-[#353535] text-[#fff4e8] rounded-full disabled:opacity-50"
                  >
                    <ChevronLeft size={16} />
                  </button>
                  <span className="mx-4 flex items-center">
                    Page {currentPage} of {Math.ceil(filteredStudents.length / studentsPerPage)}
                  </span>
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
                    className="ml-2 px-4 py-2 bg-[#353535] text-[#fff4e8] rounded-full disabled:opacity-50"
                  >
                    <ChevronRight size={16} />
                  </button>
                </div>
              </motion.div>
            ) : (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-8 transform transition-all duration-300 hover:scale-105 border border-white border-opacity-20"
            >
            <div className="flex items-center justify-between mb-4">
              <button
                onClick={handleBackToLeaderboard}
                className="flex items-center text-[#353535] hover:text-blue-600 transition-colors duration-300"
              >
                <ArrowLeft size={20} className="mr-2" />
                Back to Leaderboard
              </button>
              <h2 className="text-2xl font-bold text-center text-[#353535]">{selectedStudent.name}'s Performance</h2>
            </div>
            
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <label className="mr-2 font-medium text-[#353535]">Skill:</label>
                <select
                  value={selectedSkill}
                  onChange={(e) => setSelectedSkill(e.target.value)}
                  className="px-3 py-2 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300"
                >
                  {skills.map(skill => (
                    <option key={skill} value={skill}>{skill}</option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <label className="mr-2 font-medium text-[#353535]">Industry:</label>
                <select
                  value={selectedIndustry}
                  onChange={(e) => setSelectedIndustry(e.target.value)}
                  className="px-3 py-2 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300"
                >
                  {industries.map(industry => (
                    <option key={industry} value={industry}>{industry}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="h-96 w-full mb-8">
              {is3DView ? (
                <img 
                  src={threeDGraphImage} 
                  alt="3D Graph" 
                  className="w-full h-full object-contain"
                />
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={selectedStudent.historicalData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <XAxis dataKey="month" stroke="#353535" />
                    <YAxis domain={[0, 100]} stroke="#353535" />
                    <Tooltip 
                      contentStyle={{ 
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', 
                        border: '1px solid #353535',
                        borderRadius: '8px'
                      }} 
                    />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey={selectedSkill} 
                      stroke={colors.skill} 
                      strokeWidth={2}
                      dot={{ fill: colors.skill, strokeWidth: 2 }}
                      activeDot={{ r: 8 }} 
                      name={selectedSkill}
                    />
                    {traits.map(trait => (
                      <Line 
                        key={trait}
                        type="monotone" 
                        dataKey={trait} 
                        stroke={colors[trait]}
                        strokeWidth={2}
                        strokeOpacity={traitOpacity}
                        dot={{ fill: colors[trait], strokeWidth: 2, fillOpacity: traitOpacity }}
                        activeDot={{ r: 8 }}
                        name={trait}
                      />
                    ))}
                    <Line 
                      type="monotone" 
                      dataKey={selectedIndustry} 
                      stroke={colors.industry}
                      strokeWidth={2}
                      dot={{ fill: colors.industry, strokeWidth: 2 }}
                      activeDot={{ r: 8 }}
                      name={selectedIndustry}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="traitOpacity" className="block text-sm font-medium text-gray-700">
                Trait Line Opacity: {traitOpacity.toFixed(2)}
              </label>
              <input
                type="range"
                id="traitOpacity"
                min="0"
                max="1"
                step="0.1"
                value={traitOpacity}
                onChange={(e) => setTraitOpacity(parseFloat(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {Object.entries(colors).map(([key, color]) => (
                <div key={key} className="flex items-center">
                  <div 
                    className="w-4 h-4 rounded-full mr-2" 
                    style={{ 
                      backgroundColor: color,
                      opacity: key !== selectedSkill && key !== selectedIndustry && key !== 'skill' && key !== 'industry' ? traitOpacity : 1
                    }}
                  ></div>
                  <span className="text-sm text-[#353535]">{key}</span>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default LeaderboardComponent;