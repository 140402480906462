import React from 'react';
import { motion } from 'framer-motion';

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-secondary text-primary flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <motion.div 
        className="max-w-md w-full space-y-8 text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-4xl font-bold mb-6">Contact Us</h1>
        <p className="text-xl mb-8">
          We'd love to hear from you! Reach out and let's shape the future of careers together.
        </p>
        <div className="bg-primary bg-opacity-5 rounded-lg p-6 shadow-inner">
          <p className="text-lg mb-2">Get in touch with us at:</p>
          <a 
            href="mailto:founders@talentunboxed.com" 
            className="text-2xl font-semibold text-accent hover:underline"
          >
            founders@talentunboxed.com
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactUs;