import React from 'react';

const OrganicShape = ({ type }) => {
  const shapes = {
    languages: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-full h-full">
        <defs>
          <linearGradient id="langGrad" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#ff9a9e" />
            <stop offset="100%" stopColor="#fad0c4" />
          </linearGradient>
        </defs>
        <g fill="none" stroke="url(#langGrad)" strokeWidth="0.5">
          <path d="M0,50 Q25,25 50,50 T100,50" opacity="0.7">
            <animate attributeName="d" 
              values="M0,50 Q25,25 50,50 T100,50;
                      M0,50 Q25,75 50,50 T100,50;
                      M0,50 Q25,25 50,50 T100,50"
              dur="10s" repeatCount="indefinite" />
          </path>
          <path d="M0,70 Q25,45 50,70 T100,70" opacity="0.5">
            <animate attributeName="d" 
              values="M0,70 Q25,45 50,70 T100,70;
                      M0,70 Q25,95 50,70 T100,70;
                      M0,70 Q25,45 50,70 T100,70"
              dur="12s" repeatCount="indefinite" />
          </path>
          <path d="M0,30 Q25,5 50,30 T100,30" opacity="0.3">
            <animate attributeName="d" 
              values="M0,30 Q25,5 50,30 T100,30;
                      M0,30 Q25,55 50,30 T100,30;
                      M0,30 Q25,5 50,30 T100,30"
              dur="14s" repeatCount="indefinite" />
          </path>
        </g>
      </svg>
    ),
    whatsapp: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-full h-full">
        <defs>
          <linearGradient id="whatsappGrad" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#a8edea" />
            <stop offset="100%" stopColor="#fed6e3" />
          </linearGradient>
        </defs>
        <g fill="url(#whatsappGrad)">
          <path d="M20,80 Q50,60 80,80 L80,90 Q50,70 20,90 Z" opacity="0.7">
            <animate attributeName="d" 
              values="M20,80 Q50,60 80,80 L80,90 Q50,70 20,90 Z;
                      M20,75 Q50,55 80,75 L80,85 Q50,65 20,85 Z;
                      M20,80 Q50,60 80,80 L80,90 Q50,70 20,90 Z"
              dur="5s" repeatCount="indefinite" />
          </path>
          <path d="M20,60 Q50,40 80,60 L80,70 Q50,50 20,70 Z" opacity="0.5">
            <animate attributeName="d" 
              values="M20,60 Q50,40 80,60 L80,70 Q50,50 20,70 Z;
                      M20,55 Q50,35 80,55 L80,65 Q50,45 20,65 Z;
                      M20,60 Q50,40 80,60 L80,70 Q50,50 20,70 Z"
              dur="7s" repeatCount="indefinite" />
          </path>
          <path d="M20,40 Q50,20 80,40 L80,50 Q50,30 20,50 Z" opacity="0.3">
            <animate attributeName="d" 
              values="M20,40 Q50,20 80,40 L80,50 Q50,30 20,50 Z;
                      M20,35 Q50,15 80,35 L80,45 Q50,25 20,45 Z;
                      M20,40 Q50,20 80,40 L80,50 Q50,30 20,50 Z"
              dur="9s" repeatCount="indefinite" />
          </path>
          <circle cx="85" cy="15" r="8">
            <animate attributeName="r" values="8;10;8" dur="3s" repeatCount="indefinite" />
          </circle>
        </g>
      </svg>
    ),
    connections: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-full h-full">
        <defs>
          <linearGradient id="connectGrad" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#fbc2eb" />
            <stop offset="100%" stopColor="#a6c1ee" />
          </linearGradient>
        </defs>
        <g stroke="url(#connectGrad)" fill="url(#connectGrad)">
          <circle cx="10" cy="10" r="5" />
          <circle cx="90" cy="10" r="5" />
          <circle cx="10" cy="90" r="5" />
          <circle cx="90" cy="90" r="5" />
          <circle cx="50" cy="50" r="7">
            <animate attributeName="r" values="7;9;7" dur="3s" repeatCount="indefinite" />
          </circle>
          <path d="M10,10 Q50,0 90,10 T10,90 T90,90" fill="none" strokeWidth="0.5" opacity="0.7">
            <animate attributeName="d" 
              values="M10,10 Q50,0 90,10 T10,90 T90,90;
                      M10,10 Q50,30 90,10 T10,90 T90,90;
                      M10,10 Q50,0 90,10 T10,90 T90,90"
              dur="20s" repeatCount="indefinite" />
          </path>
        </g>
      </svg>
    ),
    lifecycle: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-full h-full">
        <defs>
          <linearGradient id="whatsappGrad" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#a8edea" />
            <stop offset="100%" stopColor="#fed6e3" />
          </linearGradient>
        </defs>
        <g fill="url(#whatsappGrad)">
          <circle cx="50" cy="50" r="48" opacity="0.3" />
          <path d="M50,2 Q80,10 98,50 Q80,90 50,98 Q20,90 2,50 Q20,10 50,2 Z" opacity="0.5">
            <animate attributeName="d" 
              values="M50,2 Q80,10 98,50 Q80,90 50,98 Q20,90 2,50 Q20,10 50,2;
                      M50,5 Q85,15 95,55 Q85,95 50,95 Q15,95 5,55 Q15,15 50,5;
                      M50,2 Q80,10 98,50 Q80,90 50,98 Q20,90 2,50 Q20,10 50,2"
              dur="15s" repeatCount="indefinite" />
          </path>
          <circle cx="50" cy="50" r="25" opacity="0.7">
            <animate attributeName="r" values="25;30;25" dur="5s" repeatCount="indefinite" />
          </circle>
        </g>
      </svg>
    )
  };

  return shapes[type] || null;
};

const OutlineIcon = ({ name }) => {
  const icons = {
    globe: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    ),
    smartphone: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
      </svg>
    ),
    users: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    ),
    briefcase: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
      </svg>
    )
  };

  return icons[name] || null;
};

const ProductCard = ({ icon, title, subtitle, body, color, abstractType, bgImage, imagePosition }) => {
    const [firstWord, ...restWords] = title.split(' ');
  
    return (
      <div 
        className={`rounded-3xl p-6 flex flex-col relative overflow-hidden shadow-lg h-[500px] group w-full ${color}`}
      >
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-5"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: imagePosition === 'up' ? 'center top' : 'center bottom'
          }}
        ></div>
        <div className="absolute inset-0 opacity-50">
          <OrganicShape type={abstractType} />
        </div>
        <div className="relative z-10 flex flex-col h-full">
          <div className="flex items-start mb-6">
            <div className="w-8 h-8 mr-4 text-gray-800 flex items-center justify-center">
              <OutlineIcon name={icon} />
            </div>
            <div className="font-mono text-lg uppercase tracking-wider text-gray-800 leading-tight">
              <div>{firstWord}</div>
              <div>{restWords.join(' ')}</div>
            </div>
          </div>
          <h3 className="font-sans text-4xl font-semibold mb-6 leading-tight text-gray-900">{subtitle}</h3>
          <p className="font-sans text-xl mb-6 flex-grow text-gray-800">{body}</p>
        </div>
      </div>
    );
  };
  
  export default ProductCard;