import React, { useMemo } from 'react';

const ParticleBackground = () => {
  const particleCount = 300; // Increased for even more density

  const particles = useMemo(() => {
    return Array.from({ length: particleCount }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 120 - 10, // Expanded range to cover bottom area
      size: Math.random() * 0.3 + 0.05, // Wider size range for variety
      speed: Math.random() * 90 + 30, // Increased speed range for more dynamic effect
      delay: Math.random() * -50, // Longer random delay for more varied twinkling
      opacity: Math.random() * 0.7 + 0.3 // Random base opacity for each particle
    }));
  }, []);

  return (
    <svg 
      className="particle-background" 
      viewBox="0 0 100 120" // Expanded viewBox to cover more area
      preserveAspectRatio="xMidYMid slice" // Changed to slice to ensure full coverage
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
    >
      <defs>
        <radialGradient id="star-gradient">
          <stop offset="0%" stopColor="white" stopOpacity="1"/>
          <stop offset="100%" stopColor="white" stopOpacity="0"/>
        </radialGradient>
      </defs>
      {particles.map((particle) => (
        <circle
          key={particle.id}
          cx={particle.x}
          cy={particle.y}
          r={particle.size}
          fill="url(#star-gradient)"
          className="particle"
          style={{
            animationDuration: `${particle.speed}s`,
            animationDelay: `${particle.delay}s`,
            opacity: particle.opacity
          }}
        />
      ))}
    </svg>
  );
};

export default ParticleBackground;