import React, { useState, useEffect, useRef } from 'react';
import { ArrowUp, GraduationCap, Briefcase, Users, Code, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
const API_URL = process.env.REACT_APP_API_URL || '/api';
const COUNSELOR_API_URL = process.env.REACT_APP_COUNSELOR_API_URL || '/counselor-api';

const BOT_BG_COLOR = 'bg-[#f0f0f0]';
const USER_BG_COLOR = 'bg-[#e0e0e0]';

const Card = ({ title, Icon, onClick }) => (
    <div 
      onClick={() => onClick(title)}
      className="p-4 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-2xl shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center space-y-2"
    >
      <Icon className="w-8 h-8 text-[#353535]" />
      <h3 className="text-sm font-semibold text-center text-[#353535]">{title}</h3>
    </div>
);


const ChatWindow = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [showCards, setShowCards] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState('start');
  const [flowData, setFlowData] = useState({});
  const [typingMessage, setTypingMessage] = useState('');
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [userName, setUserName] = useState('');
  const [currentGptQuestion] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [threadId, setThreadId] = useState('');
  const [isFixedQuestionsComplete, setIsFixedQuestionsComplete] = useState(false);
  

  const cards = [
    { title: "Help me choose my next job", Icon: Briefcase },
    { title: "Help me decide what course should I pick", Icon: GraduationCap },
    { title: "Help me change my career", Icon: Users },
    { title: "I want to talk about my career in general", Icon: Code },
  ];

  useEffect(() => {
    // JSON data
    const jsonData = {
      "start": {
        "question": "Hey there! I'm SITA (Skill improvement and Talent Advisor). What should I call you?",
        "type": "text",
        "next": "gender"
      },
      "gender": {
        "question": "It's nice to meet you [name]. I'd like to know you more so I can give you the right advice for your goals. What's your gender?",
        "options": [
            {"text": "Male", "next": "age"},
            {"text": "Female", "next": "age"},
            {"text": "Non-Binary", "next": "age"},
            {"text": "Prefer Not To Say", "next": "age"}
      ]
      },
      "age": {
        "question": "And how old are you?",
        "options": [
          {"text": "15-18", "next": "question4"},
          {"text": "19-22", "next": "question12"},
          {"text": "23-26", "next": "question13"},
          {"text": "26-onwards", "next": "question14"}
        ]
      },
      "question4": {
        "question": "I see. So you're still in school. It's awesome to see you focusing on your future from this stage! Would you like help with understanding the right career path for you?",
        "options": [
            {"text": "Yes", "next": "question5"},
            {"text": "No", "next": "question6"}
        ]
      },
      "question5": {
        "question": "Fantastic! I need to know little more about your goals and dreams. What are your interests? They could be anything from your subjects to any activities that you like to do in school.",
        "type": "text",
        "next": "question7"
      },
      "question6": {
        "question": "That's okay! Tell me what you need help with.",
        "type": "text",
        "next": "question10"
      },
      "question7": {
        "question": "That's super cool. Have you thought about what you want to be when you become an adult?",
          "options": [
            {"text": "Yes", "next": "question8"},
            {"text": "No", "next": "question9"}
          ]
      },
      "question8": {
        "question": "That's good to know. Why don't we now spend some time and understand your personality a bit? That will help me give you the best suggestions for your future. Shall we proceed?",
          "type": "exit",
          "createJSON": true
      },
      "question9": {
        "question": "That's okay! Do you want to understand what would the perfect career path for you be?",
        "options": [
          {"text": "Yes", "next": "question9Yes"},
          {"text": "No", "next": "question9No"}
        ]
      },
      "question9Yes": {
        "question": "Wonderful. Let's start from the basics. Let's go through a few personality tests to understand you better, and we'll take it from there. Shall we proceed?",
        "type": "exit",
        "createJSON": true
      },
      "question9No": {
        "question": "I don't think I can help with that. But I'd be delighted to to take this conversation forward with excitement! Shall we proceed?",
        "type": "exit",
        "createJSON": true
      },
      "question10": {
        "question": "Got it. Let's start from the basics. Let's go through a few personality tests to understand you better, and we'll take it from there. Shall we proceed?",
        "type": "exit",
        "createJSON": true
      },
      "question12": {
        "question": "I see. So you're in college now. That's awesome. What are you majoring in?",
        "type": "text",
        "next": "question15"
      },
      "question13": {
        "question": "I see. Are you now looking for a job or do you want to pursue higher studies?",
        "options": [
          {"text": "Job", "next": "question19"},
          {"text": "Higher Studies", "next": "question20"}
        ]
      },
      "question14": {
        "question": "I see. Are you working now and want help with your career, or do you want to pursue higher education?",
        "options": [
          {"text": "Working", "next": "question21"},
          {"text": "Higher Education", "next": "question21"}
        ]
      },
      "question15": {
        "question": "That's very interesting! Do you like it?",
        "options": [
          {"text": "Yes", "next": "question16"},
          {"text": "No", "next": "question17"}
        ]
      },
      "question16": {
        "question": "I'm glad to hear that! So, what are your goals that you want to achieve in your career?",
        "type": "text",
        "next": "question18"
      },
      "question17": {
        "question": "That's alright! Sometimes the best things come out of things you don't like. Like Pearls! So, what are your goals that you want to achieve in your career?",
        "type": "text",
        "next": "question18"
      },
      "question18": {
        "question": "That's good to know. Why don't we now spend some time and understand your personality a bit? That will help me give you the best suggestions for your future. Shall we proceed?",
        "type": "exit",
        "createJSON": true
      },
      "question19": {
        "question": "Awesome! Why don't we now spend some time and understand your personality a bit? That will help me give you the best suggestions for your future. Shall we proceed? ",
        "type": "exit",
        "createJSON": true
      },
      "question20": {
        "question": "That's great! What degree did you achieve in your college before?",
        "type": "text",
        "next": "question21"
      },
      "question21": {
        "question": "Can you also tell me the name of the latest company you worked for, your designation, and your total experience in years?",
        "type": "text",
        "next": "question22"
      },
      "question22": {
        "question": "Very nice! Why don't we now spend some time and understand your personality a bit? That will help me give you the best suggestions for your future. Shall we proceed?",
        "type": "exit",
        "createJSON": true
      }
    };
    setFlowData(jsonData);
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, showOptions]);

  const startConversation = (initialMessage = "") => {
    setShowCards(false);
    setMessages([
      { text: initialMessage || "Started conversation", sender: 'user' },
    ]);
    setCurrentQuestion('start');
    typeMessage(flowData['start'].question);
  };

  const typeMessage = (text, options = null) => {
    setIsTyping(true);
    setTypingMessage('');
    setShowOptions(false);
    const words = text.split(' ');
    let i = 0;
    const typing = setInterval(() => {
      setTypingMessage(prev => prev + words[i] + ' ');
      i++;
      if (i === words.length) {
        clearInterval(typing);
        setMessages(prev => [...prev, { text, sender: 'bot', options }]);
        setTypingMessage('');
        setIsTyping(false);
        if (options) {
          setTimeout(() => setShowOptions(true), 300);
        }
      }
    }, 40);
  };

  const formatUserResponses = () => {
    return Object.entries(userAnswers).reduce((acc, [key, value]) => {
      const question = flowData[key]?.question || "Unknown question";
      acc[question] = value;
      return acc;
    }, {});
  };

  const createPersona = async () => {
    setIsTyping(true);  // Show loading icon
    const personaData = {
      persona: Object.entries(userAnswers).map(([key, value]) => ({
        question: flowData[key].question,
        answer: value
      }))
    };

    try {
      const response = await fetch(`${API_URL}/create_persona`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(personaData),
      });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Persona creation response:', data);
      
      if (data.thread_id) {
        console.log('Starting counselor conversation with thread_id:', data.thread_id);
        setThreadId(data.thread_id);
        await startCounselorConversation(data.thread_id);
      } else {
        console.error('No thread_id received from create_persona');
      }
    } catch (error) {
      console.error('Error creating persona:', error);
    } finally {
      setIsTyping(false);  // Hide loading icon
    }
  };

  const startCounselorConversation = async (threadId) => {
    console.log('Starting counselor conversation');
    console.log('Using thread_id:', threadId);
  
    // Send an initial message to the counselor
    const counselorResponse = await sendMessageToCounselor("Let's start our conversation.", threadId);
    if (counselorResponse && counselorResponse.response) {
      console.log('Received counselor response:', counselorResponse);
      const parsedResponse = parseCounselorResponse(counselorResponse);
      typeMessage(parsedResponse.content, parsedResponse.options);
    } else {
      console.error('No valid response received from counselor');
      typeMessage("I'm sorry, but I didn't receive a valid response. Could you please try again?");
    }
  };

  const sendMessageToCounselor = async (message, threadId) => {
    try {
        setIsTyping(true);  // Start the loading indicator
        console.log('Sending message to counselor:', message);
        console.log('Using thread_id:', threadId);
        const response = await fetch(`${COUNSELOR_API_URL}/counselor`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread_id: threadId,
            message: message
        }),
        });

        if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}. Error: ${errorText}`);
        }

        const data = await response.json();
        console.log('Received response from counselor:', data);
        return data;
    } catch (error) {
        console.error('Error sending message to counselor:', error);
        return null;
    } finally {
        setIsTyping(false);  // Stop the loading indicator
    }
  };

  const moveToNextQuestion = async (answer) => {
    const currentQuestionData = flowData[currentQuestion];
    let nextQuestion;
  
    if (currentQuestionData.options) {
      const selectedOption = currentQuestionData.options.find(option => 
        option.text === answer || option.value === answer
      );
      nextQuestion = selectedOption ? selectedOption.next : null;
    } else {
      nextQuestion = currentQuestionData.next;
    }
  
    setUserAnswers(prev => ({ ...prev, [currentQuestion]: answer }));
  
    if (currentQuestionData.type === 'exit') {
      console.log('Reached exit point, creating persona');
      setIsFixedQuestionsComplete(true);
      await createPersona();
    } else if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
      const nextQuestionData = flowData[nextQuestion];
      let nextQuestionText = nextQuestionData.question;
      if (nextQuestion === 'gender') {
        nextQuestionText = nextQuestionText.replace('[name]', userName);
      }
      typeMessage(nextQuestionText, nextQuestionData.options);
    } else {
      console.error('No next question found');
      typeMessage("I'm sorry, but I couldn't find the next question. Please try again.");
    }
  };

  const parseCounselorResponse = (response) => {
    console.log('Raw counselor response:', response);
  
    if (typeof response === 'object' && response !== null && response.response) {
      const content = response.response;
      
      // Check if the response contains multiple choice options
      const optionsMatch = content.match(/\(([a-z])\)/g);
      if (optionsMatch) {
        const options = optionsMatch.map(match => match.replace(/[()]/g, ''));
        const questionParts = content.split(/\(([a-z])\)/);
        
        return {
          type: 'multiple_choice',
          content: questionParts[0].trim(),
          options: options.map((option, index) => ({
            text: questionParts[index * 2 + 2].trim(),
            value: option
          }))
        };
      } else {
        return {
          type: 'text',
          content: content,
          options: null
        };
      }
    }
  
    console.error('Unexpected response format:', response);
    return {
      type: 'text',
      content: 'Sorry, I received an unexpected response. Please try again.',
      options: null
    };
  };
  
  const handleSend = async (forcedAnswer = null) => {
    const answer = forcedAnswer || input.trim() || selectedOption;
    if (answer) {
      let displayAnswer = answer;
      let processedAnswer = answer;
  
      // Check if this is a multiple choice answer
      const lastMessage = messages[messages.length - 1];
      if (lastMessage?.options) {
        const selectedOption = lastMessage.options.find(option => 
          option.value === answer || option.text === answer
        );
        if (selectedOption) {
          displayAnswer = selectedOption.text;
          processedAnswer = selectedOption.value || selectedOption.text;
        }
      }
  
      if (currentQuestion === 'start') {
        setUserName(displayAnswer);
      }
  
      // Display the full text of the answer to the user
      setMessages(prev => [...prev, { text: displayAnswer, sender: 'user' }]);
      setInput('');
      setSelectedOption('');
  
      if (isFixedQuestionsComplete) {
        // We're in the counselor conversation
        setIsTyping(true);
        const counselorResponse = await sendMessageToCounselor(processedAnswer, threadId);
        setIsTyping(false);
        if (counselorResponse && counselorResponse.response) {
          const parsedResponse = parseCounselorResponse(counselorResponse);
          typeMessage(parsedResponse.content, parsedResponse.options);
        } else {
          console.error('No valid response received from counselor');
          typeMessage("I'm sorry, but I didn't receive a valid response. Please try again.");
        }
      } else {
        // We're still in fixed questions
        moveToNextQuestion(processedAnswer);
      }
    }
  };

  const LoadingIndicator = () => (
    <div className="flex items-center space-x-2 p-4">
      <div className="w-3 h-3 bg-gray-500 rounded-full animate-pulse"></div>
      <div className="w-3 h-3 bg-gray-500 rounded-full animate-pulse delay-75"></div>
      <div className="w-3 h-3 bg-gray-500 rounded-full animate-pulse delay-150"></div>
    </div>
  );

  const handleExit = () => {
    console.log("Sending answers to API:", userAnswers);
    // Implement your API call here
    setShowSummary(true);
    typeMessage("Thank you for your responses. Here's a summary of our conversation:");
  };

  const renderSummary = () => {
    return (
      <div className="mt-6 p-4 bg-white rounded-lg shadow">
        <h2 className="text-xl font-bold mb-4">Summary of Your Responses</h2>
        <pre className="whitespace-pre-wrap">
          {JSON.stringify(userAnswers, null, 2)}
        </pre>
      </div>
    );
  };

  const renderQuestionContent = () => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.options && showOptions) {
      return (
        <div className="space-y-2 mt-4">
          {lastMessage.options.map((option, index) => (
            <div key={index} className="flex items-center">
              <input
                type="radio"
                id={`option-${index}`}
                name="question-option"
                value={option.value || option.text}
                checked={selectedOption === (option.value || option.text)}
                onChange={() => {
                  setSelectedOption(option.value || option.text);
                  handleSend(option.value || option.text);
                }}
                className="mr-2 appearance-none w-4 h-4 border border-gray-300 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none"
              />
              <label htmlFor={`option-${index}`} className="text-gray-700 cursor-pointer">{option.text}</label>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleCardClick = (cardTitle) => {
    startConversation(cardTitle);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const EndTestButton = () => (
    <Link
      to="/dashboard"
      className={`
        bg-[#353535] text-white p-3 rounded-full hover:bg-opacity-80 transition-all duration-300
        flex items-center justify-center shadow-lg
        ${isFixedQuestionsComplete ? 'opacity-100 visible' : 'opacity-0 invisible pointer-events-none'}
        fixed right-8 bottom-24 z-50
        sm:right-8 sm:bottom-8
        hover:scale-105 hover:shadow-xl
      `}
    >
      <span className="text-sm whitespace-nowrap">End test & see sample report</span>
    </Link>
  );

  return (
    <div className="w-full max-w-4xl h-[calc(100vh-180px)] bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-2xl shadow-lg flex flex-col">
      <div ref={chatContainerRef} className="flex-grow overflow-y-auto p-6 mb-4">
        {showCards ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {cards.map((card, index) => (
              <Card key={index} {...card} onClick={handleCardClick} />
            ))}
          </div>
        ) : (
          <div className="space-y-6">
            {messages.map((message, index) => (
              <div key={index} className="flex flex-col">
                <span className={`inline-block p-4 rounded-2xl ${
                  message.sender === 'user' 
                    ? USER_BG_COLOR
                    : BOT_BG_COLOR
                } text-gray-800`}>
                  <p className="leading-relaxed">{message.text}</p>
                </span>
                {message.sender === 'bot' && index === messages.length - 1 && showOptions && renderQuestionContent()}
              </div>
            ))}
            {typingMessage && (
              <div className="flex flex-col">
                <span className={`inline-block p-4 rounded-2xl ${BOT_BG_COLOR} text-gray-800`}>
                  <p className="leading-relaxed">{typingMessage}</p>
                </span>
              </div>
            )}
            {isTyping && <LoadingIndicator />}
            {showSummary && renderSummary()}
          </div>
        )}
      </div>
      <div className="p-4 bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg border-t border-gray-200 sticky bottom-0">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your message..."
            className="flex-grow p-3 bg-[#e0e0e0] border-none rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800 placeholder-gray-500"
          />
          <button
            onClick={() => handleSend()}
            className="bg-blue-500 text-white p-3 rounded-full hover:bg-blue-600 transition-colors duration-300"
          >
            <ArrowUp size={20} />
          </button>
        </div>
        {isFixedQuestionsComplete && (
          <Link
            to="/dashboard"
            className="mt-4 bg-[#353535] text-white p-3 rounded-full hover:bg-opacity-80 transition-colors duration-300 flex items-center justify-center w-full"
          >
            <span className="mr-2">End test & see sample report</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ChatWindow;