import React from 'react';

const NewsletterSignup = () => {
  return (
    <section className="bg-[#fff4e8] py-16 px-4">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-start justify-between">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <p className="text-[#353535] font-sans text-xl md:text-2xl leading-tight font-light">
            Sign up for our newsletter to hear our latest<br />scientific and product updates
          </p>
        </div>
        <div className="md:w-1/2 flex flex-col w-full">
          <div className="relative w-full">
            <input
              type="email"
              placeholder="Enter email for updates*"
              className="w-full pb-2 bg-transparent border-b-2 border-[#353535] font-mono text-sm font-light focus:outline-none focus:border-[#353535] placeholder-[#353535]"
            />
            <button className="absolute right-0 bottom-2 bg-transparent text-[#353535] font-sans font-light text-sm py-1 px-4 rounded-full border border-[#353535] hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSignup;