import React, { useRef, useEffect } from 'react';

// Import college logos
/*import college1 from '../assets/images/colleges/IISC_logo.png';
import college2 from '../assets/images/colleges/IIT_Delhi_logo.png';
import college3 from '../assets/images/colleges/IIT_Bombay_logo.png';
import college4 from '../assets/images/colleges/IIM_Bangalore_Logo.png';
import college5 from '../assets/images/colleges/nift.png';
import college6 from '../assets/images/colleges/Harvard_University_shield.png';
import college7 from '../assets/images/colleges/MIT_logo.png';*/

import college1 from '../assets/images/colleges/college-1.png';
import college2 from '../assets/images/colleges/college-2.png';
import college3 from '../assets/images/colleges/college-3.png';
import college4 from '../assets/images/colleges/college-4.png';
import college5 from '../assets/images/colleges/college-5.png';
import college6 from '../assets/images/colleges/college-6.jpeg';
import college7 from '../assets/images/colleges/college-7.png';

// // Import corporate logos
// import corporate1 from '../assets/images/corporates/Accenture.png';
// import corporate2 from '../assets/images/corporates/Google__G__logo.png';
// import corporate3 from '../assets/images/corporates/Infosys_logo.png';
// import corporate4 from '../assets/images/corporates/mamaearth.png';
// import corporate5 from '../assets/images/corporates/McKinsey_and_Company_Logo_1.png';
// import corporate6 from '../assets/images/corporates/Zomato_Logo.png';
// import corporate7 from '../assets/images/corporates/Saudi_Aramco_logo.png';

import corporate1 from '../assets/images/corporates/corp-1.png';
import corporate2 from '../assets/images/corporates/corp-2.png';
import corporate3 from '../assets/images/corporates/corp-3.png';
import corporate4 from '../assets/images/corporates/corp-4.webp';

const ScrollingLogos = ({ logos }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const totalWidth = logos.length * 160; // 160px per logo (128px width + 32px margin)
    let animationFrameId;

    const scroll = () => {
      scrollContainer.style.transform = `translateX(-${scrollContainer.dataset.scroll}px)`;
      scrollContainer.dataset.scroll = (parseFloat(scrollContainer.dataset.scroll) + 0.5) % totalWidth;
      animationFrameId = requestAnimationFrame(scroll);
    };

    scrollContainer.dataset.scroll = 0;
    scroll();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [logos]);

  return (
    <div className="overflow-hidden">
      <div ref={scrollRef} className="inline-flex">
        {[...logos, ...logos].map((logo, index) => (
          <div key={index} className="flex items-center justify-center mx-4 w-32 h-20 flex-shrink-0">
            <img 
              src={logo} 
              alt="Logo" 
              className="max-w-full max-h-full object-contain filter grayscale"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TrustedBy = () => {
    const collegeLogos = [college1, college2, college3, college4, college5, college6, college7];
    const corporateLogos = [corporate1, corporate2, corporate3, corporate4];
  
    return (
      <section className="relative font-sans">
        {/* Horizontal border at the top */}
        <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-[#35353520] via-[#353535] to-[#35353520]"></div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
          <h2 className="text-lg md:text-xl text-center mb-8 md:mb-12 leading-[1.1]">Trusted By</h2>
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="w-full md:w-1/2 mb-12 md:mb-0">
              <h3 className="text-base md:text-sm mb-6 md:mb-4 text-center font-mono tracking-[.01em]">
                <span className="relative inline-block pb-2">
                  Colleges
                  <span className="absolute bottom-0 left-0 w-full h-px bg-[#353535]"></span>
                </span>
              </h3>
              <ScrollingLogos logos={collegeLogos} />
            </div>
            
            {/* Vertical border - visible only on md and up */}
            <div className="hidden md:block w-px bg-gradient-to-b from-[#35353520] via-[#353535] to-[#35353520]"></div>
            
            <div className="w-full md:w-1/2">
              <h3 className="text-base md:text-sm mb-6 md:mb-4 text-center font-mono tracking-[.01em]">
                <span className="relative inline-block pb-2">
                  Corporates
                  <span className="absolute bottom-0 left-0 w-full h-px bg-[#353535]"></span>
                </span>
              </h3>
              <ScrollingLogos logos={corporateLogos} />
            </div>
          </div>
        </div>
  
        {/* Horizontal bar at the bottom */}
        <div className="bg-[#353535] py-6 md:py-4 mt-8">
          <p className="text-center text-white font-mono text-sm md:text-base tracking-[.01em] leading-6 max-w-4xl mx-auto px-4">
            Supercharging India's career with AI - Intelligent Discovery, Preparation, Matching
          </p>
        </div>
      </section>
    );
};

export default TrustedBy;