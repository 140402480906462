import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import TrustedBy from './components/TrustedBy';
import UpcomingProducts from './components/UpcomingProducts';
import VisionSection from './components/VisionSection';
import NewsletterSignup from './components/NewsletterSignup';
import Footer from './components/Footer';
import Discovery from './components/Discovery';
import ComingSoon from './components/ComingSoon';
import EmbedComponent from './components/EmbedComponent';
import ScrollToTop from './components/ScrollToTop';
import InterviewPrepComponent from './components/InterviewPrepComponent';
import IntelligentMatchingComponent from './components/IntelligentMatchingComponent';
import PeopleSearchComponent from './components/PeopleSearchComponent';
import WarmupPage from './components/WarmupPage';
import Dashboard from './components/Dashboard';
import AboutSita from './components/AboutSita';
import ContactUs from './components/ContactUs';
import Pitch from './components/Pitch'; // Rename this import
import TechArchitecture from './components/TechArchitecture';

const Home = () => (
  <>
    <Hero />
    <TrustedBy />
    <EmbedComponent />
    <InterviewPrepComponent />
    <IntelligentMatchingComponent />
    <PeopleSearchComponent />
    <UpcomingProducts />
    <VisionSection />
    <NewsletterSignup />
  </>
);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-[#fff4e8] text-[#232323] font-sans flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/discovery" element={<Discovery />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/warmup" element={<WarmupPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/about" element={<AboutSita />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/gtm" element={<Pitch />} /> {/* Updated route */}
            <Route path="/tech" element={<TechArchitecture />} />
          </Routes>
        </main>
        <Footer />
      </div>
      {/* Updated link to Pitch page
      <div className="fixed bottom-4 right-4 z-50">
        <Link 
          to="/pitch" 
          className="bg-[#f0b157] text-white px-4 py-2 rounded-full shadow-lg hover:bg-[#e09a3e] transition-colors duration-300"
        >
          View Pitch
        </Link>
      </div>*/}
    </Router>
  );
};

export default App;