import React from 'react';
import { Linkedin, Twitter, Instagram, Slack } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="relative bg-[#fff4e8]">
      <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-[#35353520] via-[#353535] to-[#35353520]"></div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col items-center md:items-stretch md:flex-row">
          <div className="w-full md:w-1/4 mb-8 md:mb-0 md:border-r border-[#353535] md:pr-8 text-center md:text-left">
            <div className="text-4xl font-bold">sita</div>
          </div>
          <div className="w-full md:w-3/4 md:pl-8 flex flex-col items-center md:items-start">
            <nav className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 font-mono text-sm mb-8 md:mb-4 items-center md:items-start">
              <button onClick={() => window.location.href='/about'} className="w-full md:w-auto bg-transparent border border-[#353535] text-[#353535] font-thin py-2 px-4 rounded-full hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300 h-14">
                About sita
              </button>
              <button onClick={() => window.location.href='/coming-soon'} className="w-full md:w-auto bg-transparent border border-[#353535] text-[#353535] font-thin py-2 px-4 rounded-full hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300 h-14">
                Colleges
              </button>
              <button 
                onClick={() => window.open('https://hiroscope.ai/', '_blank', 'noopener,noreferrer')}
                className="w-full md:w-auto bg-transparent border border-[#353535] text-[#353535] font-thin py-2 px-4 rounded-full hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300 h-14"
                >
                Recruiters
              </button>
              <button onClick={() => window.location.href='/contact'} className="w-full md:w-auto bg-transparent border border-[#353535] text-[#353535] font-thin py-2 px-4 rounded-full hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300 h-14">
                Contact
              </button>
            </nav>
            <div className="flex space-x-4 mb-8 md:mb-4">
              <a href="#" aria-label="LinkedIn"><Linkedin size={20} /></a>
              <a href="#" aria-label="Twitter"><Twitter size={20} /></a>
              <a href="#" aria-label="Instagram"><Instagram size={20} /></a>
              <a href="#" aria-label="Slack"><Slack size={20} /></a>
            </div>
            <div className="text-sm text-[#353535] text-center md:text-left">
              © TalentUnboxed Pvt Ltd, 2024. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;