import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WordCloud from './WordCloud';
import GradientWaves from './GradientWaves';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-2 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const skills = [
    // Technology
    { text: 'AI', size: 'large' },
    { text: 'Cloud Computing', size: 'medium' },
    { text: 'Blockchain', size: 'medium' },

    // Marketing
    { text: 'Digital Marketing', size: 'large' },
    { text: 'Brand Management', size: 'medium' },
    { text: 'Content Strategy', size: 'small' },

    // Venture Capital
    { text: 'Startup Funding', size: 'large' },
    { text: 'Due Diligence', size: 'small' },
    { text: 'Portfolio Management', size: 'medium' },

    // Sales
    { text: 'B2B Sales', size: 'medium' },
    { text: 'Account Management', size: 'small' },
    { text: 'Sales Forecasting', size: 'medium' },

    // Finance
    { text: 'Financial Analysis', size: 'large' },
    { text: 'Risk Management', size: 'medium' },
    { text: 'Investment Banking', size: 'large' },

    // Design
    { text: 'UX/UI Design', size: 'large' },
    { text: 'Product Design', size: 'medium' },
    { text: 'Graphic Design', size: 'small' },

    // Programming
    { text: 'Python', size: 'large' },
    { text: 'React', size: 'medium' },
    { text: 'Machine Learning', size: 'large' },

    // Business
    { text: 'Strategic Planning', size: 'large' },
    { text: 'Entrepreneurship', size: 'medium' },
    { text: 'Business Development', size: 'large' },

    // Strategy
    { text: 'Market Research', size: 'large' },
    { text: 'Competitive Analysis', size: 'medium' },
    { text: 'Corporate Strategy', size: 'large' },

    // Creative
    { text: 'Copywriting', size: 'large' },
    { text: 'Visual Storytelling', size: 'medium' },
    { text: 'Creative Direction', size: 'large' },
];

const TypewriterText = ({ phrases }) => {
    const [text, setText] = useState('');
    const [phraseIndex, setPhraseIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
      const typingSpeed = 100;
      const deletingSpeed = 50;
      const pauseDuration = 2000;

      const typeWriter = () => {
        const currentPhrase = phrases[phraseIndex];

        if (!isDeleting && text === currentPhrase) {
          setTimeout(() => setIsDeleting(true), pauseDuration);
        } else if (isDeleting && text === '') {
          setIsDeleting(false);
          setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        } else {
          setText((prevText) => 
            isDeleting 
              ? prevText.slice(0, -1)
              : currentPhrase.slice(0, prevText.length + 1)
          );
        }
      };

      const timer = setTimeout(typeWriter, isDeleting ? deletingSpeed : typingSpeed);
      return () => clearTimeout(timer);
    }, [text, isDeleting, phraseIndex, phrases]);

    return (
      <div className="typewriter-text text-sm sm:text-base md:text-lg">
        {text}
        <span className="ml-1 animate-pulse">|</span>
      </div>
    );
};

const PeopleSearchComponent = () => {
    const phrases = [
      "I am searching fo a react native developer in HSR layout, Bangalore",
      "Help me with a investment analyst with founder's office experience in fintech",
      "Can you find a pastry chef for the weekend in Indiranagar?",
      "Is there a graphic designer who knows 3d vectors and can work remotely?"
    ];

    return (
        <section className="relative my-6 sm:my-8 md:my-12 mx-4 sm:mx-6 md:mx-8 lg:mx-16">
          <div className="rounded-3xl overflow-hidden shadow-2xl transform hover:scale-[1.01] transition-all duration-300 flex flex-col md:flex-row relative min-h-[450px] md:min-h-[500px]" style={{
            background: 'linear-gradient(135deg, #e6dfd6 0%, #d6cfc6 100%)'
          }}>
          <div className="order-1 md:order-2 md:w-1/2 p-6 sm:p-8 md:p-12 lg:p-16 flex flex-col justify-center relative z-10 text-center md:text-left py-12 md:py-0" style={{
            background: 'linear-gradient(135deg, #c6bfb6 0%, #b6afa6 100%)'
          }}>
            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold mb-3 leading-tight text-gray-800">
              AI-Powered Recruiting
            </h2>
            <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-light mb-4 text-gray-700">
              Find your perfect candidates
            </h3>
            <div className="mb-4">
              <TypewriterText phrases={phrases} />
            </div>
            <div className="flex justify-center md:justify-start">
              <Link 
                to="https://hiroscope.ai/"
                className="bg-gray-800 text-white font-mono text-xs sm:text-sm py-2 sm:py-3 px-4 sm:px-6 rounded-full hover:bg-gray-700 transition-colors duration-300 w-40 sm:w-48 md:w-56 inline-flex items-center justify-center h-10 sm:h-12 mt-3"
              >
                Start Searching
                <ArrowIcon />
              </Link>
            </div>
          </div>
          <div className="order-2 md:order-1 md:w-1/2 relative overflow-hidden py-6 md:py-0" style={{ minHeight: '360px', height: '100%' }}>
            <GradientWaves />
            <div className="absolute inset-0 flex items-center justify-center md:p-8">
              <div className="w-full h-full md:w-[120%] md:h-[120%]">
                <WordCloud skills={skills} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  export default PeopleSearchComponent;