import React from 'react';
import DiscoveryCards from './DiscoveryCards';

const Discovery = () => {
  return (
    <div className="flex-grow flex flex-col items-center justify-center px-4 py-16 sm:py-24">
      <h1 className="text-2xl sm:text-3xl font-bold mb-8 sm:mb-12">Ask sita AI anything</h1>
      <DiscoveryCards />
    </div>
  );
};

export default Discovery;