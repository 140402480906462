import React from 'react';
import { Star } from 'lucide-react';

const VisionSection = () => {
  return (
    <section className="bg-[#fff4e8] py-24 px-4 relative">
      <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-[#35353520] via-[#353535] to-[#35353520]"></div>
      <div className="max-w-7xl mx-auto text-center flex items-center justify-center">
        <Star className="text-[#353535] opacity-50 mr-8" size={48} />
        <div>
          <p className="text-3xl md:text-4xl lg:text-5xl font-sans font-[250] text-[#353535] italic leading-[1.2]">
            Join us in reshaping 1 million careers by 2029.
            Shred your limits and become your best self. From today to success.
          </p>
        </div>
        <Star className="text-[#353535] opacity-50 ml-8" size={48} />
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-[#35353520] via-[#353535] to-[#35353520]"></div>
    </section>
  );
};

export default VisionSection;