import React from 'react';
import { motion } from 'framer-motion';

const FadeInSection = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.8 }}
  >
    {children}
  </motion.div>
);

const Highlight = ({ children }) => (
  <span className="text-accent font-semibold">{children}</span>
);

const Statistic = ({ value, description }) => (
  <div className="text-center p-6 bg-primary bg-opacity-5 rounded-lg shadow-inner">
    <div className="text-5xl font-bold text-accent mb-2">{value}</div>
    <div className="text-primary text-sm">{description}</div>
  </div>
);

const AboutSita = () => {
  return (
    <div className="min-h-screen bg-secondary text-primary py-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="relative">
          {/* Glossy background effect */}
          <div className="absolute inset-0 bg-gradient-to-br from-secondary via-secondary to-primary opacity-30 rounded-3xl transform rotate-1 scale-105 blur-xl"></div>
          <div className="absolute inset-0 bg-gradient-to-tl from-secondary via-secondary to-primary opacity-30 rounded-3xl transform -rotate-1 scale-105 blur-xl"></div>
          
          {/* Main content container */}
          <div className="relative bg-secondary bg-opacity-80 backdrop-blur-sm rounded-3xl shadow-2xl p-8 sm:p-12 overflow-hidden">
            {/* Top decorative element */}
            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-accent via-primary to-accent opacity-50"></div>
            
            <FadeInSection>
              <h1 className="text-4xl sm:text-5xl font-bold text-center mb-12">
                A World Transformed by SITA AI
              </h1>
              <p className="text-xl text-center mb-16 italic">
                Journey with us to 2029, where SITA AI has revolutionized careers across India and beyond.
              </p>
            </FadeInSection>

            <FadeInSection>
              <div className="space-y-10 text-center">
                <p className="text-lg leading-relaxed">
                  Imagine waking up in India, 2029. As <Highlight>50 million young professionals</Highlight> start their day, they reach for an app that's become as essential as their morning chai - SITA AI.
                </p>

                <h2 className="text-3xl font-bold mt-12 mb-6">Your 24/7 Career Coach</h2>
                <p className="text-lg leading-relaxed">
                  SITA AI isn't just another app. It's a <Highlight>personal career coach, available round the clock</Highlight>. Trained on millions of career trajectories, it offers daily, personalized guidance, empowering professionals to navigate their careers with unprecedented confidence.
                </p>

                <div className="my-12 grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <Statistic value="50M" description="Daily Active Users" />
                  <Statistic value="24/7" description="Career Guidance" />
                </div>

                <h2 className="text-3xl font-bold mt-12 mb-6">Revolutionizing Education</h2>
                <p className="text-lg leading-relaxed">
                  From the prestigious IITs to small colleges in tier-3 cities, SITA AI has become an integral part of the curriculum. Gone are the days of blindly choosing majors. Now, SITA AI <Highlight>analyzes each student's strengths, passions, and market demands</Highlight>, guiding them towards fulfilling and in-demand career paths.
                </p>

                <h2 className="text-3xl font-bold mt-12 mb-6">Transforming Recruitment</h2>
                <p className="text-lg leading-relaxed">
                  The hiring landscape has undergone a seismic shift. <Highlight>Over 5,000 companies</Highlight>, from nimble startups to multinational giants, now rely on SITA AI for their recruitment needs. Our AI-powered asynchronous interviews and skill assessments have become the gold standard, slashing hiring times and dramatically improving job matches.
                </p>

                <div className="my-12 grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <Statistic value="5,000+" description="Partner Companies" />
                  <Statistic value="40%" description="Reduction in Skill Mismatches" />
                </div>

                <h2 className="text-3xl font-bold mt-12 mb-6">Bridging the Skill Gap</h2>
                <p className="text-lg leading-relaxed">
                  SITA AI's impact extends far beyond individual careers. We're moving the needle on a national scale. The once-daunting skill gap in India's workforce has narrowed significantly. Our data reveals a staggering <Highlight>40% reduction in skill mismatches</Highlight>, directly contributing to a 2% boost in India's GDP.
                </p>

                <h2 className="text-3xl font-bold mt-12 mb-6">Breaking Language Barriers</h2>
                <p className="text-lg leading-relaxed">
                  Our vernacular support has been nothing short of revolutionary. Quality career guidance is no longer the exclusive domain of English-speaking urbanites. SITA AI reaches aspiring professionals in the remotest corners of India, offering guidance in their native tongues and <Highlight>democratizing access to career advancement</Highlight>.
                </p>

                <h2 className="text-3xl font-bold mt-12 mb-6">A Thriving Ecosystem</h2>
                <p className="text-lg leading-relaxed">
                  With <Highlight>over 1 million mentorship connections</Highlight> and a flourishing knowledge-sharing platform, SITA AI has evolved into a movement. It's continuously reshaping the future of work, creating a world where every Indian has the tools to build a fulfilling career.
                </p>

                <div className="my-12 grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <Statistic value="1M+" description="Mentorship Connections" />
                  <Statistic value="22" description="Indian Languages Supported" />
                </div>

                <h2 className="text-3xl font-bold mt-12 mb-6">The Future is Here</h2>
                <p className="text-lg leading-relaxed">
                  As we stand in 2029, we see a workforce that's more skilled, more satisfied, and more productive than ever before. SITA AI hasn't just changed careers - it's transforming lives, fueling dreams, and driving India's progress on the global stage.
                </p>

                <p className="text-2xl font-bold mt-16 text-accent">
                  Welcome to the future of work. Welcome to a world empowered by SITA AI.
                </p>
              </div>
            </FadeInSection>
            
            {/* Bottom decorative element */}
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-accent via-primary to-accent opacity-50"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSita;