import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const jobs = [
  { title: "Software Engineer", salary: 1500000 },
  { title: "Data Analyst", salary: 1000000 },
  { title: "Product Manager", salary: 2000000 },
  { title: "UX Designer", salary: 1300000 },
  { title: "Marketing Specialist", salary: 900000 },
  { title: "Financial Advisor", salary: 1200000 },
  { title: "HR Manager", salary: 1100000 },
  { title: "Sales Representative", salary: 800000 },
  { title: "Operations Manager", salary: 1400000 },
  { title: "Content Creator", salary: 700000 },
];

const SalaryGuesser = () => {
  const [currentJob, setCurrentJob] = useState(null);
  const [guess, setGuess] = useState(1000000);
  const [result, setResult] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    getNewJobAndImage();
  }, []);

  const getNewJobAndImage = async () => {
    const newJob = jobs[Math.floor(Math.random() * jobs.length)];
    setCurrentJob(newJob);
    try {
      const response = await fetch('https://randomuser.me/api/');
      const data = await response.json();
      setImageUrl(data.results[0].picture.large);
    } catch (error) {
      console.error('Error fetching random user image:', error);
      setImageUrl('');
    }
  };

  const handleGuess = () => {
    const difference = Math.abs(guess - currentJob.salary);
    const percentage = (difference / currentJob.salary) * 100;
    
    if (percentage <= 10) {
      setResult('Excellent guess!');
    } else if (percentage <= 20) {
      setResult('Close! Not bad.');
    } else {
      setResult('Off the mark. Try again!');
    }

    setTimeout(() => {
      setResult(null);
      setGuess(1000000);
      getNewJobAndImage();
    }, 2000);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-500 flex items-center justify-center">
      <div className="bg-white p-8 rounded-3xl shadow-2xl max-w-md w-full">
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Salary Guesser</h2>
        {currentJob && (
          <motion.div
            className="bg-gray-100 p-6 rounded-2xl shadow-inner"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {imageUrl && (
              <img 
                src={imageUrl}
                alt={currentJob.title}
                className="w-40 h-40 object-cover rounded-full mx-auto mb-4"
              />
            )}
            <h3 className="text-3xl font-semibold text-center mb-4 text-gray-700">{currentJob.title}</h3>
            <input
              type="range"
              min="500000"
              max="5000000"
              value={guess}
              onChange={(e) => setGuess(parseInt(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
            <p className="text-center text-2xl font-bold mt-4 text-indigo-600">₹{guess.toLocaleString('en-IN')}</p>
          </motion.div>
        )}
        <button
          className="mt-8 w-full bg-indigo-500 text-white px-6 py-3 rounded-full text-xl font-bold hover:bg-indigo-600 transition-colors duration-300"
          onClick={handleGuess}
        >
          Guess!
        </button>
        {result && (
          <motion.p
            className="mt-4 text-center text-xl font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {result}
          </motion.p>
        )}
      </div>
    </div>
  );
};

export default SalaryGuesser;