import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AnimatedCounter from './AnimatedCounter';
import ParticleBackground from './ParticleBackground';

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ml-2 inline-block transform rotate-[-45deg]"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);

const AnimatedPerformanceText = () => (
    <motion.div
      className="flex flex-col items-center mb-8"
      animate={{
        scale: [1, 1.05, 1],
        opacity: [0.8, 1, 0.8],
      }}
      transition={{
        duration: 5,
        repeat: Infinity,
        repeatType: "reverse"
      }}
    >
      <span className="text-6xl md:text-7xl font-bold text-gray-800 leading-none">90%</span>
      <span className="text-3xl md:text-4xl font-semibold text-gray-700 mt-2">Users Benefitted</span>
    </motion.div>
);

const RadialSkillsDiagram = () => {
    const skills = [
      'Technical Skills',
      'Communication',
      'Problem Solving',
      'Industry Knowledge',
      'Leadership',
      'Teamwork',
      'Adaptability',
      'Creativity'
    ];
  
    return (
      <motion.svg
        viewBox="0 0 400 400"
        className="absolute inset-0 w-full h-full opacity-30 max-h-[300px] md:max-h-none"
        animate={{ rotate: 360 }}
        transition={{ duration: 200, repeat: Infinity, ease: "linear" }}
      >
        {skills.map((skill, index) => {
          const angle = (index / skills.length) * Math.PI * 2 - Math.PI / 2;
          const x = Math.cos(angle) * 180 + 200;
          const y = Math.sin(angle) * 180 + 200;
          const textRotation = angle * (180 / Math.PI) + (angle > Math.PI / 2 && angle < 3 * Math.PI / 2 ? 180 : 0);
          return (
            <g key={index}>
              <line
                x1="200"
                y1="200"
                x2={x}
                y2={y}
                stroke="#5a5450"
                strokeWidth="0.5"
              />
              <text
                x={x}
                y={y}
                dy=".3em"
                textAnchor="middle"
                fill="#5a5450"
                fontSize="9"
                transform={`rotate(${textRotation}, ${x}, ${y})`}
              >
                {skill}
              </text>
            </g>
          );
        })}
      </motion.svg>
    );
  };

const InterviewPrepComponent = () => {
  return (
    <section className="relative my-16 mx-4 sm:mx-8 lg:mx-16">
        <div className="rounded-3xl overflow-hidden shadow-2xl transform hover:scale-[1.01] transition-all duration-300 flex flex-col md:flex-row-reverse relative min-h-[500px]" style={{
            background: 'linear-gradient(135deg, #e6dfd6 0%, #d6cfc6 100%)'
        }}>
        <ParticleBackground />
        <div className="md:w-1/2 p-8 md:p-16 flex flex-col justify-center relative z-10 text-center md:text-left" style={{
            background: 'linear-gradient(135deg, #c6bfb6 0%, #b6afa6 100%)'
        }}>
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4 leading-tight text-gray-800">
            Interview Preparation
          </h2>
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-light mb-6 text-gray-700">
            Train for interviews
          </h3>
          <div className="mb-6 text-gray-700">
            <AnimatedCounter end={10000} /> Question Formats
          </div>
          <Link 
            to="/warmup" 
            className="bg-transparent border border-[#353535] text-[#353535] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-[#353535] hover:text-[#fff4e8] transition-colors duration-300 w-64 sm:w-auto flex items-center justify-center h-14"
          >
            Prepare Yourself
            <ArrowIcon />
          </Link>
        </div>
        <div className="md:w-1/2 relative z-10 overflow-hidden flex flex-col justify-center items-center p-8">
          <RadialSkillsDiagram />
          <div className="relative z-20 text-center">
            <AnimatedPerformanceText />
            <div>
              <p className="text-gray-700 text-xl md:text-2xl lg:text-3xl font-semibold leading-tight mb-4">
                Get connected to the right people, hear podcasts that improve your skills, and attend growth-focused sessions.
              </p>
              <p className="text-gray-700 text-lg md:text-xl lg:text-2xl leading-tight">
                Connect, learn, and grow together with peers and your idols.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InterviewPrepComponent;