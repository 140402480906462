import React, { useEffect, useRef, useState } from 'react';
import { select, shuffle } from 'd3';
import cloud from 'd3-cloud';

const WordCloud = ({ skills }) => {
  const svgRef = useRef(null);
  const [highlightedWords, setHighlightedWords] = useState([]);

  useEffect(() => {
    if (!skills || !skills.length) return;

    const svg = select(svgRef.current);
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;

    const isDesktop = window.innerWidth >= 768;

    // Adjust size calculation
    const size = isDesktop 
      ? Math.max(width, height) * 3 // Increased multiplier for desktop
      : Math.max(width, height) * 1.5; // Keep mobile as is

    svg.attr('viewBox', `0 0 ${size} ${size}`);

    // Adjust margins
    const margin = isDesktop
      ? { top: 0, right: 0, bottom: 0, left: 0 } // Remove top margin on desktop
      : { top: 20, right: 20, bottom: 20, left: 20 }; // Keep mobile margins
    const innerSize = size - margin.top - margin.bottom;

    svg.selectAll("*").remove();

    // Increase font size for desktop
    const baseSize = isDesktop ? 75 : 22; // Further increased desktop font size

    const layout = cloud()
      .size([size, innerSize])
      .words(skills.map(d => ({...d, size: d.size === 'large' ? baseSize : d.size === 'medium' ? baseSize * 0.8 : baseSize * 0.6})))
      .padding(isDesktop ? 10 : 5)
      .rotate(() => 0)
      .font("Inter, sans-serif")
      .fontSize(d => d.size)
      .spiral("archimedean")
      .on("end", draw);

    layout.start();

    function draw(words) {
      words.sort((a, b) => b.size - a.size);

      const group = svg.append("g")
        .attr("transform", `translate(${size / 2},${size / 2})`) // Center the cloud
        .selectAll("text")
        .data(words)
        .enter().append("text")
        .style("font-size", d => `${d.size}px`)
        .style("font-family", "Inter, sans-serif")
        .style("font-weight", "normal")
        .style("fill", "#4A5568")
        .style("opacity", 0.8)
        .attr("text-anchor", "middle")
        .attr("transform", d => `translate(${d.x},${d.y})`)
        .text(d => d.text);

      function updateHighlight() {
        const newHighlighted = shuffle(words).slice(0, 3);
        setHighlightedWords(newHighlighted);

        group.transition()
          .duration(500)
          .style("fill", d => newHighlighted.includes(d) ? "#2D3748" : "#4A5568")
          .style("font-weight", d => newHighlighted.includes(d) ? "bold" : "normal")
          .style("opacity", d => newHighlighted.includes(d) ? 1 : 0.8);
      }

      updateHighlight();
      const intervalId = setInterval(updateHighlight, 3000);
      return () => clearInterval(intervalId);
    }
  }, [skills]);

  return (
    <svg ref={svgRef} width="100%" height="100%" style={{ maxWidth: '100%', maxHeight: '100%' }}>
      <text x="50%" y="50%" textAnchor="middle" dy=".35em" fontFamily="Inter, sans-serif">
        {skills && skills.length ? "Loading word cloud..." : "No data available"}
      </text>
    </svg>
  );
};

export default WordCloud;