const skillsList = {
    programmingLanguages: [
      "JavaScript",
      "Python",
      "Java",
      "C++",
      "Ruby",
      "PHP",
      "Swift",
      "TypeScript",
      "Go",
      "Kotlin"
    ],
    digitalMarketing: [
      "SEO",
      "Content Marketing",
      "Social Media Marketing",
      "Email Marketing",
      "PPC Advertising",
      "Analytics",
      "Conversion Rate Optimization",
      "Marketing Automation",
      "Brand Management",
      "Influencer Marketing"
    ]
  };
  
  export default skillsList;