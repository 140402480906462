import React, { useState } from 'react';
import { ZoomIn, ZoomOut } from 'lucide-react';
import sitaArchitecture from '../assets/images/sita-architecture.png';

const TechArchitecture = () => {
  const [scale, setScale] = useState(1);

  const zoomIn = () => setScale(prev => Math.min(prev + 0.1, 2));
  const zoomOut = () => setScale(prev => Math.max(prev - 0.1, 0.5));

  return (
    <div className="container mx-auto px-4 pt-16">
      <h1 className="text-3xl font-bold mb-6 text-center">Sita AI Architecture</h1>
      <div className="flex justify-center mb-4 space-x-4">
        <button
          onClick={zoomOut}
          className="bg-[#353535] text-[#fff4e8] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-opacity-80 transition-colors duration-300 flex items-center justify-center h-14"
        >
          Zoom Out
          <ZoomOut className="ml-2 h-4 w-4" />
        </button>
        <button
          onClick={zoomIn}
          className="bg-[#353535] text-[#fff4e8] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-opacity-80 transition-colors duration-300 flex items-center justify-center h-14"
        >
          Zoom In
          <ZoomIn className="ml-2 h-4 w-4" />
        </button>
      </div>
      <div className="flex justify-center overflow-auto">
        <img 
          src={sitaArchitecture}
          alt="NexUp.ai Architecture Diagram" 
          className="max-w-full h-auto shadow-lg rounded-lg transition-transform duration-300 ease-in-out"
          style={{ transform: `scale(${scale})` }}
        />
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Architecture Explanation</h2>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold">Data Flow in NexUp.ai:</h3>

          <section>
            <h4 className="font-bold">Data Ingestion:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>User input (from both candidates and recruiters) enters the system through the Web Application or Mobile Application in the Frontend Layer.</li>
              <li>This data includes user profiles, self-discovery responses, job descriptions, search queries, etc.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">API Gateway Processing:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>All incoming data passes through the API Gateway & Load Balancer.</li>
              <li>The gateway routes the data to appropriate services in the Core Services Layer.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Core Services Processing:</h4>
            <p>Data is processed by relevant services:</p>
            <ul className="list-disc list-inside ml-4">
              <li>User Management handles authentication and profile data.</li>
              <li>Discovery Service processes self-discovery inputs.</li>
              <li>Mentorship and Training Services handle learning-related data.</li>
              <li>Recruitment Service manages job postings and candidate searches.</li>
              <li>Analytics Service begins tracking user interactions.</li>
              <li>Notification Service prepares relevant alerts.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">AI Orchestration:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>The SITA AI Orchestrator receives data from Core Services for intelligent processing.</li>
              <li>NLP Engine processes textual data.</li>
              <li>Multi-Modal Processor handles various data formats (text, audio, video).</li>
              <li>Data is routed to appropriate Specialized Language Models (SLMs).</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Specialized Language Model Processing:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>Each SLM (Discovery, Mentor, Matching, Training, Recruitment) processes domain-specific data.</li>
              <li>SLMs generate insights, recommendations, and predictions based on input data.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Data Storage:</h4>
            <p>Processed and raw data are stored in the Data & Storage Layer:</p>
            <ul className="list-disc list-inside ml-4">
              <li>User Database stores profile and interaction data.</li>
              <li>Content Database holds learning materials and job postings.</li>
              <li>AI Training Database stores data used for model training.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Machine Learning Pipeline:</h4>
            <p>Data from the storage layer feeds into the ML Pipeline:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Data Preprocessing cleans and prepares the data.</li>
              <li>Model Training uses the prepared data to update AI models.</li>
              <li>Model Evaluation assesses the performance of updated models.</li>
              <li>Model Deployment pushes improved models back to the AI Layer.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Human-in-the-Loop Interaction:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>Quality Assurance reviews AI outputs and user interactions.</li>
              <li>Content Curation manages and updates platform content.</li>
              <li>Human feedback data is fed back into the Core Services and AI Layer.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">External Services Integration:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>Payment data is processed through the Payment Gateway.</li>
              <li>Video Conferencing data (for interviews or mentoring) is handled.</li>
              <li>Email data is managed through the Email Service.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Data Output and Presentation:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>Processed data, insights, and recommendations flow back through the AI Layer to Core Services.</li>
              <li>Core Services prepare the data for presentation.</li>
              <li>Data is sent through the API Gateway to the Frontend Layer.</li>
              <li>Frontend applications present the processed data to users.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Continuous Data Flow:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>User interactions with the presented data generate new input.</li>
              <li>This new data re-enters the system, creating a continuous feedback loop.</li>
            </ul>
          </section>

          <section>
            <h4 className="font-bold">Analytics and Improvement:</h4>
            <ul className="list-disc list-inside ml-4">
              <li>The Analytics Service in Core Services Layer continuously processes user interaction data.</li>
              <li>This analyzed data helps in improving system performance and user experience.</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TechArchitecture;