import React, { useState } from 'react';
import { Database, Building2, Users, GraduationCap, Briefcase, Search, ArrowUpRight, Award } from 'lucide-react';
import WhoAreWeSlide from './WhoAreWeSlide';
import BestTeamSlide from './BestTeamSlide';
import SolutionMap from './SolutionMap';
import VisionSlide from './VisionSlide';
import WhySitaAISlide from './WhySitaAISlide';
import SitaAIFlywheel from './CareerFlywheel';
import LeaderboardComponent from './LeaderboardComponent';
import SalaryGuesser from './SalaryGuesser';
import GTMTimeline from './GTMTimeline';

const Pitch = () => {
  const [showSalaryGuesser, setShowSalaryGuesser] = useState(false);

  const toggleSalaryGuesser = () => {
    setShowSalaryGuesser(!showSalaryGuesser);
  };

  return (
    <div className="bg-[#fff4e8] min-h-screen p-8">
      <div className="max-w-6xl mx-auto space-y-16 pt-16">
        {/*<Slide>
          <div className="flex flex-col items-center justify-center h-full text-center">
            <h1 className="text-6xl font-bold mb-6 text-[#232323]">SITA AI</h1>
            <h2 className="text-4xl font-bold mb-6 text-[#232323]">Your personal career coach</h2>
            <p className="text-2xl font-semibold text-[#232323]">From Self-Discovery to Success!</p>
          </div>
        </Slide>*/}
        <Slide title="Strategic Go-to-Market Roadmap">
          <div className="grid grid-cols-3 gap-6 mb-6">
            <TimelinePhase
              title="Launch (0 months)"
              icon={<Database className="w-8 h-8 text-[#f0b157]" />}
              items={[
                <span className="font-bold text-[#f0b157]">Leverage existing database of 4.2M users</span>,
                "Set up infrastructure for B2B and B2C services",
                "Finalize pricing strategy and service offerings"
              ]}
            />
            <TimelinePhase
              title="B2B Focus (0-3 months)"
              icon={<Building2 className="w-8 h-8 text-[#f0b157]" />}
              items={[
                <span><GraduationCap className="inline mr-1" /> Schools & Colleges: <strong>Free</strong> Career Assessment Tool</span>,
                <span><GraduationCap className="inline mr-1" /> Colleges: WarmUp Tool at <strong>$0.99</strong> per automated test</span>,
                <span><Briefcase className="inline mr-1" /> Recruitment Firms: <strong>$9.99</strong> for 1hr mock interviews</span>,
                <span className="font-bold text-[#f0b157]">Onboard clients for Hiroscope.ai</span>,
                "Target: Onboard 500+ educational institutions and recruitment firms"
              ]}
            />
            <TimelinePhase
              title="B2C Expansion (3-6 months)"
              icon={<Users className="w-8 h-8 text-[#f0b157]" />}
              items={[
                "Launch social media campaign targeting individual users",
                "Host tech-specific competitions in Bangalore (UI/UX, React, GenAI, etc.)",
                <span>Individual WarmUp: <strong>$0.99</strong>/automated test, <strong>$9.99</strong>/1hr mock interview</span>,
                "Goal: Attract 100K+ individual users"
              ]}
            />
          </div>
          <div className="bg-gray-100 rounded-lg p-6 shadow-md">
            <h3 className="text-xl font-bold mb-4 text-[#232323]">Key Strategy Points</h3>
            <div className="grid grid-cols-3 gap-6">
              <StrategyPoint
                title="Leveraging Existing Data"
                description={<>Utilize <span className="font-bold text-[#f0b157]">4.2M user database</span> from partnership to jumpstart services and provide valuable insights</>}
              />
              <StrategyPoint
                title="Tiered Pricing Strategy"
                description="Offer free tools to schools, affordable options for colleges, and premium services for recruitment firms and individuals"
              />
              <StrategyPoint
                title="Phased Market Approach"
                description="Focus on B2B partnerships initially, then expand to B2C market with targeted campaigns and competitions"
              />
            </div>
          </div>
        </Slide>

        {/*<Slide title="GTM Timeline">
          <GTMTimeline />
        </Slide>*/}

        <SitaAIFlywheel />
        <LeaderboardComponent />
        
        <div className="flex justify-center">
          <button
            onClick={toggleSalaryGuesser}
            className="bg-indigo-500 text-white px-6 py-3 rounded-full text-xl font-bold hover:bg-indigo-600 transition-colors duration-300"
          >
            {showSalaryGuesser ? 'Hide Salary Guesser' : 'Show Salary Guesser'}
          </button>
        </div>

        {showSalaryGuesser && <SalaryGuesser />}
      </div>
    </div>
  );
};

const Slide = ({ title, children }) => {
  return (
    <div className="bg-white rounded-xl shadow-lg w-full relative" style={{ aspectRatio: '16/9' }}>
      <div className="absolute inset-0 p-8 pb-12">
        {title && <h2 className="text-3xl font-bold mb-6 text-[#232323]">{title}</h2>}
        <div className="h-full overflow-hidden">{children}</div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-8 bg-[#f0b157] flex items-center justify-between px-4">
        <span className="text-white text-xs font-semibold">Confidential</span>
        <span className="text-white text-xs font-semibold">sita.ai</span>
      </div>
    </div>
  );
};

const TimelinePhase = ({ title, icon, items }) => (
  <div className="bg-gray-100 rounded-lg p-4 shadow-md">
    <div className="flex items-center mb-3">
      {icon}
      <h3 className="text-lg font-bold text-[#232323] ml-2">{title}</h3>
    </div>
    <ul className="space-y-2">
      {items.map((item, index) => (
        <li key={index} className="flex items-start">
          <span className="text-[#f0b157] mr-2 text-lg">•</span>
          <span className="text-sm">{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

const StrategyPoint = ({ title, description }) => (
  <div>
    <h4 className="font-bold text-base text-[#232323] mb-2">{title}</h4>
    <p className="text-sm text-[#232323]">{description}</p>
  </div>
);

const CareerValueChainSlide = () => {
  return (
    <div className="h-full w-full flex flex-col">
      <h1 className="text-3xl font-bold mb-12 text-gray-800 mt-4">What's missing in the world of career?</h1>
      
      <div className="flex-grow flex flex-col justify-start items-center space-y-12">
        <span className="text-3xl font-bold text-gray-800">Career Value Chain</span>
        
        <div className="bg-gray-100 rounded-lg p-6 w-full">
          <div className="grid grid-cols-5 gap-4">
            {['Self Exploration', 'UpSkilling', 'Employability', 'Occupation', 'Social'].map((item, index) => (
              <div key={index} className="flex flex-col items-center bg-white rounded-lg p-3 shadow-sm">
                <div className="bg-gray-100 rounded-full p-2 flex items-center justify-center mb-2">
                  <Icon item={item} />
                </div>
                <span className="font-semibold text-gray-700 text-center text-xs">{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <div className="mt-12 text-center">
        <p className="text-xl font-semibold text-gray-700">Fragmented Offerings - No Single Source Of Intelligence</p>
      </div>
    </div>
  );
};

const Icon = ({ item }) => {
  const iconClass = "w-5 h-5 text-[#f0b157]";
  switch(item) {
    case 'Self Exploration': return <Search className={iconClass} />;
    case 'UpSkilling': return <ArrowUpRight className={iconClass} />;
    case 'Employability': return <Briefcase className={iconClass} />;
    case 'Occupation': return <Award className={iconClass} />;
    case 'Social': return <Users className={iconClass} />;
    default: return null;
  }
};

export default Pitch;