import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import OpenAI from 'openai';
import { ChevronDown, Search, X, TrendingUp, Loader } from 'lucide-react';
import { JobDescriptionInput, ExperienceSelector, TimeLimitSelector, InterviewQuestions, ResultModal } from './WarmupSupportingComponents';
import skillsList from '../data/skillsList';

// Initialize the OpenAI client
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const callOpenAI = async (prompt) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: prompt }],
      temperature: 0.7,
    });
    return response.choices[0].message.content;
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw error;
  }
};

const allSkills = Object.values(skillsList).flat();
const trendingSkills = allSkills.slice(0, 5); // Assume first 5 skills are trending

const getQuestionCount = (timeLimit) => {
  const timeLimitMap = {
    '15': 10,
    '30': 12,
    '45': 15,
    '60': 18,
    '75': 22,
    '90': 25
  };
  return timeLimitMap[timeLimit] || 5;
};

const WarmupPage = () => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const [experience, setExperience] = useState('');
  const [timeLimit, setTimeLimit] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [showQuestions, setShowQuestions] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(null);
  const dropdownRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [review, setReview] = useState('');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredSkills = searchTerm
    ? allSkills
        .filter(skill => skill.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
          const aLower = a.toLowerCase();
          const bLower = b.toLowerCase();
          const searchTermLower = searchTerm.toLowerCase();

          if (aLower === searchTermLower && bLower !== searchTermLower) return -1;
          if (aLower !== searchTermLower && bLower === searchTermLower) return 1;
          if (aLower.startsWith(searchTermLower) && !bLower.startsWith(searchTermLower)) return -1;
          if (!aLower.startsWith(searchTermLower) && bLower.startsWith(searchTermLower)) return 1;
          return aLower.localeCompare(bLower);
        })
    : allSkills;

  const handleSkillSelect = (skill) => {
    if (selectedSkills.length < 3 && !selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills, skill]);
    }
    setSearchTerm('');
    setIsDropdownOpen(false);
    setJobDescription('');
  };

  const handleRemoveSkill = (skill) => {
    const updatedSkills = selectedSkills.filter(s => s !== skill);
    setSelectedSkills(updatedSkills);
    if (updatedSkills.length === 0) {
      setJobDescription('');
    }
  };

  const handleJobDescriptionChange = (value) => {
    setJobDescription(value);
    if (value.trim() !== '') {
      setSelectedSkills([]);
    }
  };

  const handleContinue = async () => {
    const newErrors = {};
    if (selectedSkills.length === 0 && !jobDescription) {
      newErrors.skills = true;
      newErrors.jobDescription = true;
    }
    if (!experience) newErrors.experience = true;
    if (!timeLimit) newErrors.timeLimit = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    try {
      const questionCount = getQuestionCount(timeLimit);
      let prompt;
      if (selectedSkills.length > 0) {
        prompt = `I have an interview coming up which requires the following skillsets: ${selectedSkills.join(', ')}. 
        I would like to prepare by practicing with relevant subjective questions tailored to my experience level of ${experience} years. 
        I have 5 minutes lesser than ${timeLimit} minutes, available for this practice session. 
        Please generate a set of questions with the following distribution: 
        40% technical questions that delve into the core skills listed. 
        40% practical, real-world scenarios or tasks that demonstrate the application of these skills, keeping technical requirements as a point of view. 
        20% behavioral or situational questions to assess soft skills and fit. 
        The questions should be presented in random order and should not be categorized by type. 
        Ensure the questions are engaging and reflect my experience level. 
        Provide all the questions at once, with clear and concise formatting for easy review. 
        Limite the number of questions to ${questionCount}.`;
      } else {
        prompt = `I have an interview coming up for this job description: ${jobDescription}. 
        I would like to prepare by practicing with relevant subjective questions tailored to my experience level of ${experience} years. 
        I have 5 minutes lesser than ${timeLimit} minutes, available for this practice session. 
        Please generate a set of questions with the following distribution: 
        40% technical questions that delve into the core skills listed. 
        40% practical, real-world scenarios or tasks that demonstrate the application of these skills, keeping technical requirements as a point of view. 
        20% behavioral or situational questions to assess soft skills and fit. 
        The questions should be presented in random order and should not be categorized by type. 
        Ensure the questions are engaging and reflect my experience level. 
        Provide all the questions at once, with clear and concise formatting for easy review. 
        Limite the number of questions to ${questionCount}.`;    }
      const response = await callOpenAI(prompt);
      const parsedQuestions = response.split('\n').filter(q => q.trim() !== '');
      setQuestions(parsedQuestions);
      setShowQuestions(true);
    } catch (error) {
      console.error("Failed to start interview:", error);
      alert("Failed to start interview. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswerSubmit = (index, answer) => {
    setAnswers(prev => ({ ...prev, [index]: answer }));
  };

  const handleInterviewComplete = async (answers) => {
    const questionsAndAnswers = questions.map((q, index) => ({
      question: q,
      answer: answers[index] || ''
    }));
  
    let prompt;
    if (selectedSkills.length > 0) {
      prompt = `Here are questions and answers for an upcoming interview in ${selectedSkills.join(', ')} skills. 
      Please evaluate only the answers provided by the user, not the questions, which were generated by AI. 
      For each answer, provide an individual score on a scale from 0 to 100 based on the following criteria: 
      Technical correctness (primary focus), 
      Relevance to the question, 
      Depth of the answer, 
      Clarity and communication. 
      After evaluating all the answers, calculate the average score across all answers.
      Additionally, provide a one-liner overall review of the candidate's performance that is both constructive and empathetic. 
      Return only the result in the following JSON format: 
        {
        "average_score": average_score_value, 
        "review": "one-liner overall review"
        }: ${JSON.stringify(questionsAndAnswers)};`;
    } else {
        prompt = `Here are questions and answers for an upcoming interview with this Job description - ${jobDescription}.  
        Please evaluate only the answers provided by the user, not the questions, which were generated by AI. 
        For each answer, provide an individual score on a scale from 0 to 100 based on the following criteria: 
        Technical correctness (primary focus), 
        Relevance to the question, 
        Depth of the answer, 
        Clarity and communication. 
        After evaluating all the answers, calculate the average score across all answers. 
        Additionally, provide a one-liner overall review of the candidate's performance that is both constructive and empathetic. 
        Return only the result in the following JSON format: 
          {
          "average_score": average_score_value, 
          "review": one-liner overall review
          }: ${JSON.stringify(questionsAndAnswers)};`;
    }
    
    try {
        setIsLoading(true);
        const response = await callOpenAI(prompt);
        const parsedResponse = JSON.parse(response);
        setScore(parsedResponse.average_score);
        setReview(parsedResponse.review);
        setShowResult(true);
      } catch (error) {
        console.error("Failed to get interview score:", error);
        alert("Failed to get interview score. Please try again.");
      } finally {
        setIsLoading(false);
      }    
  };

  return (
    <div className="flex-grow flex flex-col items-center justify-center px-4 py-16 sm:py-24 bg-[#fff4e8]">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-3xl"
      >
        <h1 className="text-4xl font-bold mb-8 text-center text-[#353535]">Prepare for Your Interview</h1>
        
        {!showQuestions ? (
          <motion.div 
            className="bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-8 transform transition-all duration-300 hover:scale-105 border border-white border-opacity-20"
            whileHover={{ boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)" }}
          >
            <p className="text-xl mb-6 text-center text-[#353535]">
              Select your skillset to start practicing for your interview or simply paste your job description (JD) in the box below.
            </p>
            
            {jobDescription.trim() === '' && (
              <motion.div 
                className={`mb-3 relative ${errors.skills ? 'animate-shake' : ''}`}
                animate={errors.skills ? { x: [-10, 10, -10, 10, 0] } : {}}
                ref={dropdownRef}
              >
                <label className="block text-sm font-medium text-[#353535] mb-2">
                  Select Skills (up to 3) <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <Search className="absolute left-3 top-3 text-gray-400" size={20} />
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setIsDropdownOpen(true);
                    }}
                    onFocus={() => setIsDropdownOpen(true)}
                    className="w-full px-10 py-3 text-[#353535] bg-[#F7F2ED] border-2 border-[#353535] rounded-xl focus:outline-none focus:ring-2 focus:ring-[#353535] transition-all duration-300"
                    placeholder="Search skills or select from the list"
                  />
                </div>
                <AnimatePresence>
                  {isDropdownOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute z-10 w-full mt-1 bg-white border-2 border-[#353535] rounded-xl shadow-lg max-h-60 overflow-auto"
                    >
                      {filteredSkills.map((skill) => (
                        <button
                          key={skill}
                          onClick={() => handleSkillSelect(skill)}
                          className={`w-full text-left px-4 py-2 hover:bg-[#F7F2ED] ${
                            selectedSkills.includes(skill) ? 'bg-[#F7F2ED] font-semibold' : ''
                          }`}
                        >
                          {skill}
                          {trendingSkills.includes(skill) && (
                            <TrendingUp className="inline-block ml-2 text-blue-500" size={16} />
                          )}
                        </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
  
            <div className="flex flex-wrap gap-2 mb-4">
              {selectedSkills.map((skill) => (
                <motion.span
                  key={skill}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  className="bg-[#353535] text-[#fff4e8] px-3 py-1 rounded-full text-sm flex items-center"
                >
                  {skill}
                  <button
                    onClick={() => handleRemoveSkill(skill)}
                    className="ml-2 focus:outline-none"
                  >
                    <X size={14} />
                  </button>
                </motion.span>
              ))}
            </div>
            
            {selectedSkills.length === 0 && (
              <>
                <div className="text-center text-[#353535] font-bold my-2">OR</div>
                <motion.div
                  className={`mb-6 ${errors.jobDescription ? 'animate-shake' : ''}`}
                  animate={errors.jobDescription ? { x: [-10, 10, -10, 10, 0] } : {}}
                >
                  <JobDescriptionInput 
                    jobDescription={jobDescription}
                    setJobDescription={handleJobDescriptionChange}
                    isRequired={true}
                  />
                </motion.div>
              </>
            )}
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
              <motion.div
                className={`${errors.experience ? 'animate-shake' : ''}`}
                animate={errors.experience ? { x: [-10, 10, -10, 10, 0] } : {}}
              >
                <ExperienceSelector 
                  experience={experience}
                  setExperience={setExperience}
                  isRequired={true}
                />
              </motion.div>
              
              <motion.div
                className={`${errors.timeLimit ? 'animate-shake' : ''}`}
                animate={errors.timeLimit ? { x: [-10, 10, -10, 10, 0] } : {}}
              >
                <TimeLimitSelector 
                  timeLimit={timeLimit}
                  setTimeLimit={setTimeLimit}
                  isRequired={true}
                />
              </motion.div>
            </div>
            
            <motion.button 
              onClick={handleContinue}
              className="w-full bg-[#353535] text-[#fff4e8] font-mono font-thin text-sm py-4 px-8 rounded-full hover:bg-opacity-80 transition-colors duration-300 mt-8 flex items-center justify-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader className="animate-spin mr-2" size={16} />
                  Preparing Interview...
                </>
              ) : (
                <>
                  Start Interview Practice
                  <ChevronDown className="ml-2" size={16} />
                </>
              )}
            </motion.button>
          </motion.div>
        ) : (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white bg-opacity-60 backdrop-filter backdrop-blur-xl rounded-3xl shadow-2xl p-8 border border-white border-opacity-30"
          >
            {questions.length > 0 ? (
              <InterviewQuestions 
                questions={questions}
                onAnswerSubmit={handleAnswerSubmit}
                onComplete={handleInterviewComplete}
                isLoading={isLoading}
              />
            ) : (
              <p className="text-center text-[#353535]">No questions generated. Please try again.</p>
            )}
          </motion.div>
        )}
        
        {showResult && (
        <ResultModal 
        score={score} 
        review={review} 
        onClose={() => setShowResult(false)} 
        />
        )}
      </motion.div>
    </div>
  );
};

export default WarmupPage;